import React, { useState } from 'react';
import styles from "./AutoAssignModal.module.css";
import { collection, addDoc } from 'firebase/firestore';
import { db } from "../../../../../firebase-config";
import closeIcon from "./close.png";
import ButtonComponent from '../../../../../components/CssComponents/ButtonComponent/ButtonComponent';
import { notification } from 'antd';
import { setTargetToClubAdmin } from '../../../../../service/targets/setTargetToClubAdmin';

function AutoAssignModal({ isAutoAssignModal, setIsAutoAssignModal }) {
    const [targetNumber, setTargetNumber] = useState("");
    const [targetMonth, setTargetMonth] = useState("");
    const [targetYear, setTargetYear] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent page reload

        if (!targetNumber || !targetMonth || !targetYear) {
            alert("Please fill all fields.");
            return;
        }

        try {
            const targetRef = collection(db, "targets");
            await addDoc(targetRef, {
                targetNumber: parseInt(targetNumber),
                targetMonth,
                targetYear,
                createdAt: new Date(),
            });

            notification.success({
                message: "Target Set in Super Admin",
                description: "Target Set",
                placement: "topRight",
                duration: 3,
            });

            await setTargetToClubAdmin({ targetNumber, targetMonth, targetYear });

            setIsAutoAssignModal(false);
            setTargetNumber("");
            setTargetMonth("");
            setTargetYear("");

        } catch (error) {
            notification.error({
                message: "Error setting target",
                description: "Error setting target",
                placement: "topRight",
                duration: 3,
            });
        }
    };

    return (
        <div className={isAutoAssignModal ? `${styles.modal} ${styles.displayBlock}` : `${styles.modal} ${styles.displayNone}`}>
            <div className={styles.modalMain}>
                <button
                    className={styles.closeBtn}
                    onClick={() => setIsAutoAssignModal(false)}
                >
                    <img src={closeIcon} alt="Close" />
                </button>
                <div className={styles.mainContent}>
                    <div className={styles.title}>Set Target</div>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.labelInputWrapper}>
                            <div className={styles.labelText}>Target Number</div>
                            <input
                                type="number"
                                className={styles.input}
                                value={targetNumber}
                                onChange={(e) => setTargetNumber(e.target.value)}
                                placeholder="Enter target number"
                                required
                            />
                        </div>
                        <div className={styles.labelInputWrapper}>
                            <div className={styles.labelText}>Target Month</div>
                            <input
                                type="number"
                                className={styles.input}
                                value={targetMonth}
                                onChange={(e) => setTargetMonth(e.target.value)}
                                placeholder="Enter target month (1-12)"
                                min="1"
                                max="12"
                                required
                            />
                        </div>
                        <div className={styles.labelInputWrapper}>
                            <div className={styles.labelText}>Target Year</div>
                            <input
                                type="number"
                                className={styles.input}
                                value={targetYear}
                                onChange={(e) => setTargetYear(e.target.value)}
                                placeholder="Enter target year"
                                required
                            />
                        </div>
                        <ButtonComponent
                            buttonText={"Set Target"}
                            buttonType='submit'
                        />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AutoAssignModal;
