import { signOut } from "firebase/auth";
import { auth } from "../firebase-config";
import { notification } from "antd";

const logout = async () => {
  try {
    await signOut(auth);
    notification.info({
      message: 'Logout',
      description: `You have been logged out successfully!");`,
      placement: 'topRight',
      duration: 3,
    });
    window.location.href = "/";
  } catch (error) {
    notification.error({
      message: 'Failed to logout',
      description: `Please try again.`,
      placement: 'topRight',
      duration: 3,
    });
  }
};

export default logout;
