import React, { useEffect, useState } from 'react'
import "./SaleTarget.css"
import Box from "../../../../components/box/Box"
import user from "./users.png"
import { getListOfTeams } from '../../../../service/getListOfTeams'
import ButtonComponent from "../../../../components/CssComponents/ButtonComponent/ButtonComponent"
import AutoAssignModal from './AutoAssignModal/AutoAssignModal'


const SalesTarget = () => {
  const [isAutoAssignModal, setIsAutoAssignModal] = useState(false);
  const [teams, setTeams] = useState([])
  useEffect(() => {
    const fetch = async () => {
      const teams = await getListOfTeams()
      console.log("T E A M S", teams);

      setTeams(teams)
    }
    fetch()
  }, [])
  return (
    <div className='saletarget'>
      <h1 className="heading"> Sale & Targetssss</h1>
      <div className="boxes">
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
      </div>
      <div className="salecontent">
        <div className="bigcontainer">
          <h1 className="bigheading">Club Decided Target : ₹222040</h1>
          <ButtonComponent
            buttonText={"Auto Assign"}
            onClick={() => setIsAutoAssignModal(true)}
          />
        </div>

        <h1 className="heading">Team List</h1>

        <table className='table' cellSpacing={0}>
          <thead className='tablehead'>
            <tr>
              <th>ID</th>
              <th>Team Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody className='tablebody'>

            <tr>
              <td>id</td>
              <td>name</td>
              <td>
                <input type="number" placeholder='Enter Amount' className="inputinstall" />
              </td>


            </tr>

          </tbody>
        </table>
      </div>

      {isAutoAssignModal && <AutoAssignModal
        isAutoAssignModal={isAutoAssignModal}
        setIsAutoAssignModal={setIsAutoAssignModal}
      />
      }

    </div>
  )
}

export default SalesTarget