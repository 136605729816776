import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../../firebase-config";
import { useRef, useState } from "react";
import styles from "./Payment.module.css"
import close from "./close.png"
import ButtonComponent from "../../../../../components/CssComponents/ButtonComponent/ButtonComponent";
import uploadPng from "./upload.png"

const PaymentModal = ({ isOpen, onClose, onConfirm, courseId, userId, installmentDate }) => {

    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [selectedFilePreview, setSelectedFilePreview] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isHover, setIsHover] = useState(false);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile); // Store the selected file for uploading later.
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedFilePreview(reader.result); // Show a preview of the image.
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleConfirm = async () => {
        if (!file) {
            alert("Please upload a payment proof.");
            return;
        }

        try {
            setIsUploading(true);

            // Create a unique file path
            const filePath = `paymentProofs/${userId}/${courseId}_${installmentDate}_${file.name}`;
            const fileRef = ref(storage, filePath);

            // Upload the file to Firebase Storage
            await uploadBytes(fileRef, file);

            // Get the downloadable URL
            const fileUrl = await getDownloadURL(fileRef);

            // Pass the file URL to the parent `onConfirm`
            onConfirm(fileUrl);
            onClose();
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("Failed to upload payment proof. Please try again.");
        } finally {
            setIsUploading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className={isOpen ? `${styles.modal} ${styles.displayBlock}` : `${styles.modal} ${styles.displayNone}`}>
            <div className={styles.modalMain}>
                <div
                    className={styles.closebtn}
                    onClick={onClose}>
                    <img src={close} alt="" />
                </div>

                <div className={styles.mainc}>
                    <div className={styles.heading}>Upload Payment Proof</div>
                    <div className={styles.imageUpload}>
                        <img
                            onClick={() => fileInputRef.current.click()}
                            src={selectedFilePreview || uploadPng}
                            alt={"Selected file"}
                            className={styles.profilePhoto}
                        />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                        <ButtonComponent
                            className={styles.btn}
                            onClick={handleConfirm}
                            disabled={isUploading}
                            buttonText={isUploading ? "Uploading..." : "Confirm"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentModal;