import React, { useState, useEffect } from "react";
import styles from '../../../styles/DashboardStyles/Dashboard.module.css';
import logout from "../../../service/logout.js";
import Sidebar from "./Sidebar.jsx";
import Header from "./Header.jsx";
import Edu from "./EduProg/Edu.js"
import Logout from "../../../components/LogoutModal/Logout";
import UserNotification from "../../../components/FlowComponents/userNotification/UserNotification.jsx";
import UserProfile from "../../../components/FlowComponents/userProfile/UserProfile.jsx";
import { sampleNotificationData } from "../../../service/notificationService/sampleNotificationData.js";
import { useAuth } from "../../../provider/Auth.provider.jsx";
import { findUserByUID } from "../../../service/findUsers/findUserByUID.js";


const Dashboard = () => {
    const { user } = useAuth();
    const [userData, setUserData] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [notifyModal, setNotifyModal] = useState(false);
    const [profileModal, setProfileModal] = useState(false);

    const [activeElement, setActiveElement] = useState('education');
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);


    useEffect(() => {
        const fetchUserData = async () => {
            const userData = await findUserByUID(user.uid);
            setUserData(userData);
        };
        fetchUserData();
    }, [user]);


    useEffect(() => {
        const handleResize = () => {
            setIsMenuExpanded(false);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const handleClick = (element) => setActiveElement(element);

    return (
        <div className={`${styles.dashboard}`}>


            <Sidebar
                activeElement={activeElement}
                handleClick={handleClick}
                openModal={() => setShowModal(true)}
                isMenuExpanded={isMenuExpanded}
            />


            <div className={styles.maincontent}>
                <Header
                    userData={userData}
                    openNotifyModal={() => setNotifyModal(true)}
                    openProfileModal={() => setProfileModal(true)}
                    toggleMenu={() => setIsMenuExpanded(!isMenuExpanded)}
                />
                {activeElement === 'education' ? <Edu /> : null}
            </div>

            <Logout
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                handleLogout={logout}
            />
            <UserNotification
                showModal={notifyModal}
                closeModal={() => setNotifyModal(false)}
                sampleNotificationData={sampleNotificationData}
            />
            <UserProfile
                userData={userData}
                showModal={profileModal}
                closeModal={() => setProfileModal(false)}
            />
        </div>
    );
};

export default Dashboard;
