import React, { useEffect, useState } from "react";
import "./SaleTarget.css";
import Box from "../../../../components/box/Box";
import user from "./users.png";
import { getEarningsForMonthAndYear, getListOfTeams } from "../../../../service/getListOfTeams";
import { FaAnglesLeft } from "react-icons/fa6";
import { FaAnglesRight } from "react-icons/fa6";
import { getFirestore, doc, collection, setDoc } from "firebase/firestore";
import { notification } from "antd";
import { fn } from "moment/moment";
import TargetTable from "./TargetTable/TargetTable";

const SalesTarget = () => {
  const [teams, setTeams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [inputValues, setInputValues] = useState({}); // State to hold input values

  useEffect(() => {
    const fetch = async () => {
      const teams = await getListOfTeams();
      console.log("T E A M S", teams);
      setTeams(teams);
    };
    fetch();
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(teams.length / itemsPerPage);
  const currentTeams = teams.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  // Handle input change
  const handleInputChange = (teamId, value) => {
    setInputValues((prev) => ({
      ...prev,
      [teamId]: value,
    }));
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  // Function to handle auto-assign button click
  const handleAutoAssign = async (teamId, fName, lName) => {
    const amount = inputValues[teamId] || "0";


    // Get current month and year
    const now = new Date();
    const month = now.getMonth() + 1; // JavaScript months are 0-indexed
    const year = now.getFullYear();
    const summ = await getEarningsForMonthAndYear(teamId, month, year)

    console.log(" SU M A M O U N T", summ);

    // Firestore setup
    const db = getFirestore();

    try {
      // Reference to the goals subcollection for the specific user
      const goalDocRef = doc(
        collection(db, `users/${teamId}/goals`),
        `${month}-${year}`
      );

      // Create or update the document in the goals subcollection
      await setDoc(goalDocRef, { month, year, amount: parseFloat(amount) }, { merge: true });

      // Success Notification
      notification.success({
        message: "Goal Assigned",
        description: `Goal of ₹${amount} successfully assigned for ${fName} ${lName} for Month ${month}, ${year}.`,
        placement: "topRight",
      });
    } catch (error) {
      // Error Notification
      notification.error({
        message: "Error Assigning Goal",
        description: "There was an error assigning the goal. Please try again.",
        placement: "topRight",
      });
      console.error("Error assigning goal:", error);
    }
  };

  return (
    <div className="saletarget">
      <h1 className="heading">Sale & Target</h1>
      <div className="boxes">
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
      </div>
      <div className="salecontent">
        <div className="bigcontainer">
          <h1 className="bigheading">Club Decided Target : ₹222040</h1>
          <div className="btn">Auto Assign</div>
        </div>

        <div>
          <h1 className="heading">Target Data</h1>
          <TargetTable />
        </div>

        <h1 className="heading">Team List</h1>

        <table className="table" cellSpacing={0}>
          <thead className="tablehead">
            <tr>
              <th>ID</th>
              <th>Team Name</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tablebody">
            {currentTeams.map((team) => (
              <tr key={team.id}>
                <td>{team.myARID}</td>
                <td>{`${team.firstName} ${team.lastName}`}</td>
                <td>
                  <input
                    type="number"
                    placeholder="Enter Amount"
                    className="inputinstall"
                    value={inputValues[team.id] || ""}
                    onChange={(e) =>
                      handleInputChange(team.id, e.target.value)
                    }
                  />
                </td>
                <td className="btns">
                  <button className="btn"
                    onClick={() => handleAutoAssign(team.id, team.firstName, team.lastName)}
                  >
                    Auto Assign
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            className="btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <FaAnglesLeft />
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaAnglesRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalesTarget;
