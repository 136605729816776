import React, { useEffect, useState } from "react";
import styles from "./progress.module.css";
import { db } from "../../../../../firebase-config";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { updateUserEarnings } from "../../../../../service/updateEarnings/updateUserEarnings";
import { useAuth } from "../../../../../provider/Auth.provider";
import { addDoc, Timestamp } from "firebase/firestore";

import PaymentModal from "../PaymentModal/PaymentModal";

// import ButtonComponent from "../../../../../components/CssComponents/ButtonComponent/ButtonComponent";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../../firebase-config";
import { saveTransaction } from "../../../../../service/saveTransactions";



import ButtonComponent from "../../../../../components/CssComponents/ButtonComponent/ButtonComponent";
import { findUserByUID } from "../../../../../service/findUsers/findUserByUID";
import { findUserWhoReferedMe } from "../../../../../service/findUsers/findUserWhoReferedMe";



const Progress = ({ data }) => {
  const { user } = useAuth();
  const [courses, setCourses] = useState(data);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null); // Data for the modal

  const openModal = (courseIndex, installmentDate) => {
    setModalData({ courseIndex, installmentDate });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalData(null);
  };

  useEffect(() => {
    setCourses(data);
  }, [data]);



  const handlePayNow = async (fileUrl) => {
    try {
        const { courseIndex, installmentDate } = modalData;
        const selectedCourse = courses[courseIndex];

        // ✅ Find the specific installment
        const installmentIndex = selectedCourse.installments.findIndex(
            (inst) => new Date(inst.date).toISOString() === new Date(installmentDate).toISOString()
        );

        if (installmentIndex === -1) {
            alert("Installment not found.");
            return;
        }

        const installment = selectedCourse.installments[installmentIndex];
        const paymentAmount = parseFloat(installment.price);

        if (isNaN(paymentAmount) || paymentAmount <= 0) {
            alert("Invalid installment amount.");
            return;
        }

        // ✅ Fetch user details
        const userDetl = await findUserByUID(user.uid);
        const referringUser = await findUserWhoReferedMe(userDetl.referralId);

        if (referringUser) {
            // ✅ Update referring user's earnings
            await updateUserEarnings(referringUser.uid, paymentAmount, user.uid);
        }

        // ✅ Update Installment in Firestore
        const courseRef = doc(db, "courses", selectedCourse.id);
        const updatedInstallments = [...selectedCourse.installments];

        const now = new Date();
        const newPaymentEntry = {
            userId: user.uid,
            day: now.getDate(), // Extracts day (1-31)
            proof:fileUrl,
            month: now.getMonth() + 1, // Extracts month (0-11) and adjusts to (1-12)
            year: now.getFullYear(), // Extracts year (e.g., 2025)
        };

        updatedInstallments[installmentIndex] = {
            ...installment,
            paid: installment.paid ? [...installment.paid, newPaymentEntry] : [newPaymentEntry]
        };

        await updateDoc(courseRef, { installments: updatedInstallments });

        // ✅ Add transaction record
        await saveTransaction(
            user.uid,
            paymentAmount,
            fileUrl,
            `Installment payment for course: ${selectedCourse.courseName}`
        );

        // ✅ Update local state
        const updatedCourses = [...courses];
        updatedCourses[courseIndex] = { ...selectedCourse, installments: updatedInstallments };
        setCourses(updatedCourses);

        alert("Payment successful!");
    } catch (error) {
        console.error("Error processing payment:", error);
        alert("Payment failed. Please try again.");
    }
};



  return (
    <div className={styles.progress}>
      {
        courses.map((course, index) => {
          const combinedData = [
            ...course.modules.map((mod) => ({ ...mod, type: "module" })),
            ...course.installments.map((inst) => ({ ...inst, type: "installment" })),
          ];

          const sortedData = combinedData.sort((a, b) => new Date(a.date) - new Date(b.date));

          return (
            <div key={index} className={styles.courseSection}>
              <h3 className={styles.heading}>{course.courseName}</h3>

              <div className={styles.dialogs}>
                {sortedData.map((item, itemIndex) => {
                  const isPaid =
                    item.type === "installment" &&
                    item.paid &&
                    Array.isArray(item.paid) &&
                    item.paid.some(payment => payment.userId === user.uid);

                  return (
                    <div key={itemIndex} className={styles.dialog}>
                      <div className={styles.subHeading}>
                        {item?.name || item?.type || "N/A"}
                      </div>

                      {item.price && (
                        <p className={styles.text}>Price: ₹{item.price}</p>
                      )}
                      <p className={styles.text}>Date: {item.date}</p>

                      {item.type === "installment" && !isPaid && (
                        <ButtonComponent
                          onClick={() => openModal(index, item.date)}
                          buttonText={"PAY NOW"}
                        />
                      )}

                      {item.type === "installment" && isPaid && (
                        <p className={styles.text}>Paid</p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      <PaymentModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handlePayNow}
        courseId={modalData?.courseId}
        userId={user.uid}
        installmentDate={modalData?.installmentDate}
      />
    </div>

  );
};

export default Progress;






