import React, { useEffect, useState } from "react";
import { Drawer, Upload, Modal, ConfigProvider, notification } from "antd";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import styles from "./CourseDrawer.module.css";
import qrCode from "./qr_code.jpg";
import ButtonComponent from "../../../../components/CssComponents/ButtonComponent/ButtonComponent";
import { findCourseRegistrationPriceById } from "../../../../service/findCoursePriceById";
import { db } from "../../../../firebase-config";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { saveTransaction } from "../../../../service/saveTransactions";

const CourseDrawer = ({ isVisible, onClose, course, uid }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState([]);
    const [proofUrl, setProofUrl] = useState("");

    useEffect(() => {
        console.log(course);
    }, [course]);

    // ✅ Handle Course Purchase
    const handleBuyCourse = async () => {
        try {
            // ✅ Step 1: Validate Proof URL
            if (!proofUrl) {
                notification.warning({
                    message: "Upload Required",
                    description: "Please upload a proof of payment before proceeding.",
                    placement: "topRight",
                    duration: 3,
                });
                return;
            }

            // ✅ Step 2: Fetch the User Document
            const userRef = doc(db, "users", uid);
            const userSnapshot = await getDoc(userRef);

            if (!userSnapshot.exists()) {
                console.error("User not found!");
                return;
            }

            const userData = userSnapshot.data();
            const courseId = course?.id; // Ensure `course.id` is used correctly

            // ✅ Step 3: Check if User Already Has the Course
            if (userData.courseIdsArray?.some((entry) => entry.courseId === courseId)) {
                notification.warning({
                    message: "Already Enrolled",
                    description: "You have already enrolled in this course.",
                    placement: "topRight",
                    duration: 3,
                });
                return;
            }

            // ✅ Step 4: Fetch Course Price
            const coursePrice = await findCourseRegistrationPriceById(courseId);
            if (!coursePrice) {
                console.error("Course price not found!");
                notification.error({
                    message: "Course Not Found",
                    description: "The course price could not be retrieved.",
                    placement: "topRight",
                    duration: 3,
                });
                return;
            }

            // ✅ Step 5: Create a New Course Entry
            const newCourseEntry = {
                courseId: courseId,
                proof: proofUrl,
                status: "pending",
            };

            // ✅ Step 6: Update User's `courseIdsArray`
            await updateDoc(userRef, {
                courseIdsArray: arrayUnion(newCourseEntry), // ✅ Add new course
            });

            console.log("Course successfully added:", newCourseEntry);

            // ✅ Step 7: Save Transaction
            await saveTransaction(uid, coursePrice, proofUrl, "Course Purchase");

            console.log("Transaction successfully saved!");
            notification.success({
                message: "Congratulations!",
                description: "You have purchased the course successfully.",
                placement: "topRight",
                duration: 3,
            });

            // ✅ Step 8: Close Drawer After Successful Purchase
            onClose();
        } catch (error) {
            console.error("Error purchasing course:", error);
            notification.error({
                message: "Purchase Failed",
                description: "There was an error processing your course purchase.",
                placement: "topRight",
                duration: 3,
            });
        }
    };

    // ✅ Handle File Upload to Firebase
    const handleUpload = async ({ file, onSuccess, onError }) => {
        try {
            const storage = getStorage();
            const storageRef = ref(storage, `course_payments/${file.name}`);

            // Upload file to Firebase Storage
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);

            console.log("Uploaded File URL:", downloadURL);
            setProofUrl(downloadURL);

            // ✅ Update fileList with `status: "done"` to remove "Uploading..."
            setFileList((prevList) => [
                ...prevList,
                { uid: file.uid, name: file.name, url: downloadURL, status: "done" },
            ]);

            onSuccess(); // ✅ Notify Ant Design that upload was successful
        } catch (error) {
            console.error("Error uploading file:", error);
            onError(error); // Notify Ant Design about failure
        }
    };

    const handlePreview = async (file) => {
        setPreviewImage(file.url || file.thumbUrl);
        setPreviewVisible(true);
    };

    const handleCancelPreview = () => setPreviewVisible(false);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgBase: "#b17e32",
                    colorBgLayout: "#1e1e1e",
                    colorText: "#ffffff",
                    colorTextSecondary: "#b3b3b3",
                    colorBorder: "#b17e32",
                    colorPrimary: "#cf9f41",
                    colorPrimaryHover: "#40a9ff",
                    colorBgContainer: "#2a2a2a",
                    colorBgElevated: "#3a3a3a",
                },
            }}
        >
            <Drawer
                title={course?.packageTitle || "Course Details"}
                placement="right"
                onClose={onClose}
                open={isVisible}
                width={350}
                closeIcon={<CloseCircleOutlined style={{ color: "#b17e32" }} />}
            >
                <div className={styles.container}>
                    {/* Header Section */}
                    <div className={styles.header}>
                        <img
                            src={course?.packageImage}
                            alt={course?.packageTitle}
                            className={styles.courseImage}
                        />
                        <div className={styles.courseInfo}>
                            <div className={styles.courseTitle}>{course?.packageTitle}</div>
                            <div className={styles.coursePrice}>
                                Original Price : {course?.packagePriceCross}
                            </div>
                            <div className={styles.coursePrice}>
                                Discount Price : {course?.packagePrice}
                            </div>
                        </div>
                    </div>

                    {/* QR Code Section */}
                    <div className={styles.qrCodeSection}>
                        <img src={qrCode} alt="QR Code" className={styles.qrCodeImage} />
                        <p className={styles.qrCodeText}>Scan QR Code to Pay!</p>
                    </div>

                    {/* Upload Section */}
                    <div className={styles.uploadSection}>
                        <Upload
                            customRequest={handleUpload} // ✅ Upload to Firebase
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            showUploadList={{ showRemoveIcon: false }}
                        >
                            {fileList.length < 5 && (
                                <div>
                                    <UploadOutlined />
                                    <div style={{ marginTop: 8 }}>Upload Screenshot</div>
                                </div>
                            )}
                        </Upload>

                        <Modal open={previewVisible} footer={null} onCancel={handleCancelPreview}>
                            <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
                        </Modal>
                    </div>

                    {/* Actions Section */}
                    <div className={styles.actions}>
                        <ButtonComponent className={styles.btn} onClick={handleBuyCourse} buttonText={"Buy"} />
                        <ButtonComponent className={styles.btn} buttonText={"Cancel"} onClick={onClose} />
                    </div>
                </div>
            </Drawer>
        </ConfigProvider>
    );
};

export default CourseDrawer;
