import React, { useEffect, useState } from "react";
import styles from "../../../../../styles/Table.module.css"
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../../../../firebase-config";
import { useAuth } from "../../../../../provider/Auth.provider"
import ButtonComponent from "../../../../../components/CssComponents/ButtonComponent/ButtonComponent";
import { setTargetToClubLeader } from "../../../../../service/targets/setTargetToClubLeader";

const TargetTable = () => {
    const { user } = useAuth()
    const [targets, setTargets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const uid = user?.uid;
        if (!uid) return;

        const fetchTargets = async () => {
            try {
                const targetsRef = collection(db, `users/${uid}/targets`);
                const q = query(targetsRef, orderBy("createdAt", "desc"));
                const querySnapshot = await getDocs(q);

                const fetchedTargets = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    createdAt: doc.data().createdAt?.toDate().toLocaleString() || "N/A"
                }));

                setTargets(fetchedTargets);
            } catch (error) {
                console.error("Error fetching targets:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTargets();
    }, [user?.uid]);

    return (
        <>
            {loading ? (
                <p>Loading targets...</p>
            ) : targets.length === 0 ? (
                <p>No targets found.</p>
            ) : (
                <table className={styles.table}>
                    <thead className={styles.tablehead}>
                        <tr>
                            <th>Target Number</th>
                            <th>Target Month</th>
                            <th>Target Year</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className={styles.tablebody}>
                        {targets.map((target) => (
                            <tr key={target.id}>
                                <td>{target.targetNumber}</td>
                                <td>{target.targetMonth}</td>
                                <td>{target.targetYear}</td>
                                <td>{target.createdAt}</td>
                                <td>
                                    <ButtonComponent
                                        className={styles.btn}
                                        buttonText={"Auto Assign"}
                                        onClick={() => setTargetToClubLeader({
                                            targetNumber: target.targetNumber,
                                            targetMonth: target.targetMonth,
                                            targetYear: target.targetYear
                                        })}
                                    />
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default TargetTable;
