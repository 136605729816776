import React, { useState, useEffect } from "react";
import Graph from "../../../../components/Graph/Graph";
import Bargraph from "../../../../components/Graph/Bargraph";
import { notification } from "antd";
import Table from "./Table/Table";
import FullTable from "./FullTable/FullTable";

import { getMyARIDFromUid } from "../../../../service/getMyARIDFromUid";
import { findUsersUsingMyARID } from "../../../../service/findUsers/findUsersUsingMyARID.js"
import UserProfileCard from "../../../../components/FlowComponents/userProfileCard/UserProfileCard.jsx";
import { useAuth } from "../../../../provider/Auth.provider.jsx";

const TeamRep = () => {
  const { user, loading } = useAuth();

  const [fullDataItem, setFullDataItem] = useState(null);
  const [referredUsers, setReferredUsers] = useState(null);

  const handleViewFull = (item) => {
    setFullDataItem(item);
  };

  const handleBack = () => {
    setFullDataItem(null);
  };


  useEffect(() => {
    const fetchUsersReferredByUid = async () => {
      const myARID = await getMyARIDFromUid(user.uid);
      if (myARID) {
        // this is an array
        const usersUsingMyARID = await findUsersUsingMyARID(myARID);

        if (usersUsingMyARID.length > 0) {
          console.log(usersUsingMyARID); // Log the users array
          setReferredUsers(usersUsingMyARID);
          // alert("Total Users using this myARID as referralId: " + usersUsingMyARID.length);
        } else {
          notification.info({
            message: 'No users found with this referralId.',
            description: `No users found with this referralId.`,
            placement: 'topRight',
            duration: 3,
          });
        }
      } else {
        notification.info({
          message: 'AR ID is empty',
          description: `AR ID not found`,
          placement: 'topRight',
          duration: 3,
        });
      }
    }
    fetchUsersReferredByUid();
  }, [user.uid]);



  return (
    <div className="edu">

      {
        fullDataItem === null && (
          <div className="graphes">
            <Graph />
            <Bargraph />
          </div>
        )
      }

      {/* {
        fullDataItem ? (
          <FullTable item={fullDataItem} onBack={handleBack} />
        ) : (
          <Table data={referredUsers} onViewFull={handleViewFull} />
        )
      } */}

      {/* display in another place, we can display here,  */}
      {/* pass item={fullDataItem} */}
      {
        fullDataItem ? (
          // full data of single team members
          <UserProfileCard item={fullDataItem} onBack={handleBack} />
        ) : (
          // all team members
          <Table data={referredUsers} onViewFull={handleViewFull} />
        )
      }
    </div>
  );
};

export default TeamRep;
