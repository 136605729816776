import React from 'react';
import styles from '../../../styles/DashboardStyles/Header.module.css';

import menu from "./assets/menu.png"
import coin from './assets/coin.png';
import notif from './assets/notif.png';
import profile from './assets/profile.png';
import expand from './assets/expand.png';

const Header = ({ userData, toggleMenu, openNotifyModal, openProfileModal }) => {
    console.log(userData);
    console.log("header")
    return (
        <div className={styles.header}>
            <div className={styles.left}>
                <div className={styles.menugrid} onClick={toggleMenu}>
                    <img src={menu} alt="Menu" />
                </div>
            </div>
            <div className={styles.right}>
                <div
                    onClick={openProfileModal}
                    className={styles.profile}>
                    <img src={userData.photoUrl || profile} alt="Profile" />
                </div>
            </div>
        </div>
    );
};

export default Header;
