import React, { useState } from "react";
import "./Pay.css";
import close from "./close.png";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { savePayment, saveTransaction } from "../../service/saveTransactions";

const Pay = ({ showPayModal, closePayModal, userPayInfo, selectedId }) => {
  const [paymentAmount, setPaymentAmount] = useState(""); // State for payment amount
  const [paymentProof, setPaymentProof] = useState(null); // State for payment proof (file)

  // Handle file change
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setPaymentProof(e.target.files[0]);
    }
  };

  // Handle payment submission (optional)
  const handlePayClick = async () => {
    if (!paymentAmount || !paymentProof) {
      alert("Please provide payment amount and upload proof.");
      return;
    }
  
    try {
      // Initialize Firebase storage
      const storage = getStorage();
      const storageRef = ref(storage, `transactions/${selectedId}/${paymentProof.name}`);
  
      // Upload the proof file to Firebase Storage
      const snapshot = await uploadBytes(storageRef, paymentProof);
  
      // Get the download URL for the uploaded file
      const proofUrl = await getDownloadURL(snapshot.ref);
  console.log("I D",selectedId);
  
      // Save the transaction with the provided details
      await saveTransaction(selectedId, paymentAmount, proofUrl, "Paid to trainer");
      await savePayment(selectedId,paymentAmount,proofUrl )
  
      // Success message
      alert("Transaction saved successfully!");
      console.log("Transaction saved successfully with the following details:");
      console.log({
        userId: selectedId,
        amount: paymentAmount,
        proofUrl,
        reason: "Paid to trainer",
      });
  
      // Reset modal and states
      setPaymentAmount("");
      setPaymentProof(null);
      closePayModal();
    } catch (error) {
      console.error("Error during payment process:", error);
      alert("Failed to process the transaction. Please try again.");
    }
  };

  return (
    <div className={showPayModal ? "modal display-block" : "modal display-none"}>
      <section className="modal-main">
        <div className="closebtn" onClick={closePayModal}>
          <img src={close} alt="Close" />
        </div>
        <div className="mainc">
          <h1 className="headinga">Are you sure?</h1>
          <div className="middlecontent">
            <p>First Name = {userPayInfo?.firstName || "NA"} </p>
            <p>Account Number = {userPayInfo?.accountNumber || "NA"} </p>
            <p>Account Type = {userPayInfo?.accountType || "NA"}</p>
            <p>IFSC Code = {userPayInfo?.ifscCode || "NA"}</p>
          </div>

          <div className="input-section">
            <div className="input-wrapper">
              <label htmlFor="paymentAmount" className="label">
                Enter Payment Amount:
              </label>
              <input
                type="number"
                id="paymentAmount"
                className="input"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                placeholder="Enter amount"
                required
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="paymentProof" className="label">
                Upload Payment Proof:
              </label>
              <input
                type="file"
                id="paymentProof"
                className="file-input"
                accept="image/*"
                onChange={handleFileChange}
                required
              />
              {paymentProof && (
                <p className="file-name">Selected File: {paymentProof.name}</p>
              )}
            </div>
          </div>

          <div className="btnc">
            <div onClick={closePayModal} className="btn">
              Cancel
            </div>
            <div onClick={handlePayClick} className="btn">
              Pay
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pay;
