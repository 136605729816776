import { findMonthlyEarningsForUser } from "../findMonthlyEarningsForUser";
import { getClubTrainers } from "../getUsers/getClubTrainers";

/**
 * Fetch club trainers along with their latest payment and monthly earnings.
 * @returns {Promise<Array>} - Array of trainers with earnings and latest payment info.
 */
export const getClubTrainersEarnings = async () => {
  try {
    const clubTrainers = await getClubTrainers();

    // Fetch earnings & latest payment for each trainer
    const clubTrainersEarnings = await Promise.all(
      clubTrainers.map(async (trainer) => {
        const monthlyEarnings = await findMonthlyEarningsForUser(trainer.id);

        // ✅ Extract the latest payment entrys
        const latestPayment =
          trainer.payments.length > 0
            ? trainer.payments.reduce((latest, payment) =>
                new Date(payment.year, payment.month - 1, payment.day) >
                new Date(latest.year, latest.month - 1, latest.day)
                  ? payment
                  : latest
              )
            : null;

        return {
          ...trainer,
          monthlyEarnings,
          latestPayment, // ✅ Store only the latest payment
        };
      })
    );

    return clubTrainersEarnings;
  } catch (error) {
    console.error("Error fetching club trainers with earnings:", error);
    return [];
  }
};
