import React, { useState } from 'react'
import FeedbackReview from '../../../../components/FlowComponents/FeedbackReview/FeedbackReview'
import FeedbackTable from '../../../../components/FlowComponents/FeedbackTable/FeedbackTable'
import styles from "../../../../styles/Review.module.css"

const MemberReviews = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className={styles.Reviews}>
      <FeedbackTable setShowModal={setShowModal} />
      {
        showModal && <FeedbackReview showModal={showModal} setShowModal={setShowModal} />
      }
    </div>
  )
}

export default MemberReviews