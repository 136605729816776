import React, { useEffect, useState } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../../firebase-config';
import { notification } from 'antd';
import styles from "../../../styles/Signin.module.css"
import LoginInput from '../../../components/FlowComponents/LoginInput/LoginInput';
import LoginOtpVerify from '../../../components/FlowComponents/LoginOtpVerify/LoginOtpVerify';
import ArLogo from "../../../components/CssComponents/ArLogo/ArLogo"
import backbutton from "./backbutton.png"
import { findUserByUID } from '../../../service/findUsers/findUserByUID';

const Signin = () => {
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [showSign, setShowSign] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure the recaptcha-container element exists
    const recaptchaContainer = document.getElementById('recaptcha-container');
    if (recaptchaContainer) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, recaptchaContainer, {
        'size': 'invisible',
        'callback': (response) => {
          console.log('reCAPTCHA solved');
        }
      });
    }

    // Cleanup on component unmount
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);


  // receiving where is getting called
  // from child components
  const handleSignInClick = (phone) => {
    sendVerificationCode(phone);
    setShowSign(false);
  };

  const handleOtpVerify = (otpComing) => {
    verifyOtp(otpComing);
  };

  const sendVerificationCode = (phone) => {
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        notification.success({
          message: 'OTP sent to your phone',
          description: 'OTP sent to your phone',
          placement: 'topRight',
          duration: 3,
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to send OTP',
          description: 'Please try again.',
          placement: 'topRight',
          duration: 3,
        });
      });
  };

  const verifyOtp = (otp) => {
    if (!confirmationResult) {
      notification.info({
        message: 'OTP Request Needed',
        description: 'First request the OTP',
        placement: 'topRight',
        duration: 3,
      }); return;
    }
    confirmationResult.confirm(otp)
      .then(async (result) => {
        const user = result.user;
        const userDetails = await findUserByUID(user.uid);
        if (!userDetails || userDetails.userTypes !== 'team_leader') {
          notification.info({
            message: 'User Details not found',
            description: `Redirecting to register in 5 seconds`,
            placement: 'topRight',
            duration: 5,
          });
          setTimeout(() => {
            navigate('/student?action=register', { replace: true });
          }, 5000);
        }
        notification.success({
          message: 'Phone number & user verified',
          description: `Phone number : ${user.phoneNumber}, UserType : ${userDetails.userTypes}`,
          placement: 'topRight',
          duration: 3,
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Verification Failed',
          description: `${error.message}`,
          placement: 'topRight',
          duration: 3,
        });
      });
  };

  return (
    <div className={styles.signin}>
      <div className={styles.container}>
        {
          showSign ? <></> :
            <div
              onClick={() => setShowSign(true)}
              className={styles.icon}>
              <img
                className={styles.iconImage}
                src={backbutton}
                alt={"Back Button"}
              />
            </div>
        }
        <div id="recaptcha-container"></div>
        <ArLogo />
        <div className={styles.heading}>Student Login</div>
        {
          showSign ?
            <LoginInput
              onSignInClick={handleSignInClick}
            /> :
            <LoginOtpVerify
              onOtpVerify={handleOtpVerify}
            />
        }
        <Link
          to="/student?action=register"
          style={{
            textDecoration: 'none',
          }}
        >
          <p className={styles.naa}>Need an account? <span>Register</span></p>
        </Link>
      </div>
    </div>
  );
};

export default Signin;
