import React from "react";
import styles from "./ArLogo.module.css";
import arLogo from "./arLogo.png"

function ArLogo({ src = arLogo, altText = "Logo", customStyles }) {
    return (
        <div className={`${styles.logoWrapper} ${customStyles ? customStyles : ""}`}>
            <img
                className={styles.logoImg}
                src={src}
                alt={altText}
            />
        </div>
    );
}


export default ArLogo;
