import React, { useState } from 'react';
import ButtonComponent from '../../CssComponents/ButtonComponent/ButtonComponent';
import styles from "./LoginInput.module.css"
import PhoneInputComponent from '../../CssComponents/PhoneInput/PhoneInputComponent';
import { notification } from 'antd';

const LoginInput = ({ onSignInClick }) => {
    const [phone, setPhone] = useState('');

    const handleSignIn = () => {
        if (!phone) {
            notification.error({
                message: 'Please enter phone number',
                description: `10 digit Indian phone number`,
                placement: 'topRight',
                duration: 3,
            });
            return;
        }
        onSignInClick(phone);
    };

    return (
        <div className={styles.loginContainer}>
            <div className={styles.phoneInputWrapper}>
                <p className={styles.label}>Enter Phone <sup>*</sup></p>
                <PhoneInputComponent
                    value={phone}
                    onChange={setPhone}
                    placeholder="Enter phone number"
                />
            </div>
            <ButtonComponent
                buttonText="Sign In - GET OTP"
                onClick={handleSignIn}
                className=""
            />
        </div>
    );
};

export default LoginInput;
