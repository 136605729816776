// ContactUs.js
import React, { useEffect, useState } from "react";
import styles from "./ContactUs.module.css";
import Header from "../landing/header/Header";
import Footer from "../landing/Footer/Footer";
import Aos from "aos";
import HeaderLoginOptions from "../components/LandingComponents/HeaderLoginOptions/HeaderLoginOptions";
import HeaderRegisterOptions from "../components/LandingComponents/HeaderRegisterOptions/HeaderRegisterOptions";
import ButtonComponent from "../components/CssComponents/ButtonComponent/ButtonComponent";

const ContactUs = () => {
    useEffect(() => {
        const originalStyles = {
            fontFamily: document.body.style.fontFamily,
            margin: document.body.style.margin,
            padding: document.body.style.padding,
            boxSizing: document.body.style.boxSizing,
            outline: document.body.style.outline,
            border: document.body.style.border,
            textDecoration: document.body.style.textDecoration,
            fontSize: document.documentElement.style.fontSize,
            overflowX: document.documentElement.style.overflowX,
            scrollBehavior: document.documentElement.style.scrollBehavior,
            scrollPaddingTop: document.documentElement.style.scrollPaddingTop,
        };

        // Apply global styles
        document.body.style.fontFamily = '"DM Sans", sans-serif';
        document.body.style.margin = "0";
        document.body.style.padding = "0";
        document.body.style.boxSizing = "border-box";
        document.body.style.outline = "none";
        document.body.style.border = "none";
        document.body.style.textDecoration = "none";
        document.documentElement.style.fontSize = "62.5%";
        document.documentElement.style.overflowX = "hidden";
        document.documentElement.style.scrollBehavior = "smooth";
        document.documentElement.style.scrollPaddingTop = "7rem";

        return () => {
            // Cleanup global styles
            document.body.style.fontFamily = originalStyles.fontFamily;
            document.body.style.margin = originalStyles.margin;
            document.body.style.padding = originalStyles.padding;
            document.body.style.boxSizing = originalStyles.boxSizing;
            document.body.style.outline = originalStyles.outline;
            document.body.style.border = originalStyles.border;
            document.body.style.textDecoration = originalStyles.textDecoration;
            document.documentElement.style.fontSize = originalStyles.fontSize;
            document.documentElement.style.overflowX = originalStyles.overflowX;
            document.documentElement.style.scrollBehavior =
                originalStyles.scrollBehavior;
            document.documentElement.style.scrollPaddingTop =
                originalStyles.scrollPaddingTop;
        };
    }, []);


    useEffect(() => {
        // Initialize AOS
        Aos.init();
    }, []);



    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [activeHash, setActiveHash] = useState(window.location.hash || "#home");
    const [isHovered, setIsHovered] = useState(false);

    const openRegister = () => {
        setIsRegisterOpen(true);
    };

    const closeRegister = () => {
        setIsRegisterOpen(false);
    };

    const openLogin = () => {
        setIsHovered(false)
        setIsLoginOpen(true);
    };

    const closeLogin = () => {
        setIsLoginOpen(false);
    };

    // Update `activeHash` when URL fragment changes
    useEffect(() => {
        const handleHashChange = () => {
            setActiveHash(window.location.hash || "#home");
        };

        // Listen for hash changes
        window.addEventListener("hashchange", handleHashChange);

        // Cleanup event listener
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);
    return (
        <div className={styles.page}>
            <HeaderLoginOptions isLoginOpen={isLoginOpen} closeLogin={closeLogin} />
            <HeaderRegisterOptions isRegisterOpen={isRegisterOpen} closeRegister={closeRegister} />

            <Header
                activeHash={activeHash}
                openLogin={openLogin}
                openRegister={openRegister}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
            />
            <div className={styles.contactUsContainer}>
                <div className={styles.contactFormSection}>
                    <h2 className={styles.heading}>Contact Us</h2>

                    <form className={styles.formContainer}>
                        <div className={styles.labelInputWrapper}>
                            <label htmlFor="name" className={styles.labelText}>Name</label>
                            <input type="text" id="name" placeholder="Your Name" className={styles.input} />
                        </div>

                        <div className={styles.labelInputWrapper}>
                            <label htmlFor="email" className={styles.labelText}>Email</label>
                            <input type="email" id="email" placeholder="Your Email" className={styles.input} />
                        </div>

                        <div className={styles.labelInputWrapper}>
                            <label htmlFor="message" className={styles.labelText}>Message</label>
                            <textarea
                                id="message"
                                placeholder="Your Message"
                                rows="5"
                                className={styles.inputTextArea}
                            ></textarea>
                        </div>

                        <ButtonComponent
                            buttonText={"Send Message"}
                            buttonType="submit"
                        />
                    </form>
                </div>

                <div className={styles.mapSection}>
                    <iframe
                        title="Our Location"
                        className={styles.map}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.933989983619!2d88.38199947512504!3d22.656249629899687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89d4e876c3d37%3A0x58211349336f180a!2sAffiliate%20Ritual!5e0!3m2!1sen!2sin!4v1738605663824!5m2!1sen!2sin"
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
            <Footer />
        </div>

    );
};

export default ContactUs;
