import { db } from "../../firebase-config";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { notification } from "antd";

export const setTargetToClubAdmin = async ({ targetNumber, targetMonth, targetYear }) => {
    // Validate inputs
    if (!targetNumber || !targetMonth || !targetYear) {
        notification.warning({
            message: "Missing Fields",
            description: "All fields (Target Number, Month, Year) are required.",
            placement: "topRight",
            duration: 3,
        });
        return;
    }

    // Ensure numeric values are valid
    const parsedTargetNumber = parseInt(targetNumber);
    const parsedTargetMonth = parseInt(targetMonth);
    const parsedTargetYear = parseInt(targetYear);

    if (
        isNaN(parsedTargetNumber) || parsedTargetNumber <= 0 ||
        isNaN(parsedTargetMonth) || parsedTargetMonth < 1 || parsedTargetMonth > 12 ||
        isNaN(parsedTargetYear) || parsedTargetYear < 2000
    ) {
        notification.warning({
            message: "Invalid Input",
            description: "Ensure target number is positive, month is between 1-12, and year is valid.",
            placement: "topRight",
            duration: 3,
        });
        return;
    }

    try {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("userTypes", "==", "club_admin"));
        const querySnapshot = await getDocs(q);

        // Check if there are club admins
        if (querySnapshot.empty) {
            notification.info({
                message: "No Club Admins Found",
                description: "No users with role 'club_admin' were found. No targets were assigned.",
                placement: "topRight",
                duration: 3,
            });
            return;
        }

        // Prepare target data
        const targetData = {
            targetNumber: parsedTargetNumber,
            targetMonth: parsedTargetMonth,
            targetYear: parsedTargetYear,
            createdAt: new Date(),
        };

        // Assign targets to club admins
        const promises = querySnapshot.docs.map(async (doc) => {
            const userTargetRef = collection(db, `users/${doc.id}/targets`);
            await addDoc(userTargetRef, targetData);
        });

        await Promise.all(promises); // Ensure all writes are completed

        notification.success({
            message: "Targets Assigned Successfully",
            description: `Targets have been set for ${querySnapshot.size} club administrators.`,
            placement: "topRight",
            duration: 3,
        });
    } catch (error) {
        console.error("Error setting targets for club admins:", error);
        notification.error({
            message: "Error Assigning Targets",
            description: "Could not assign targets to club admins. Please try again later.",
            placement: "topRight",
            duration: 3,
        });
    }
};
