import React, { useEffect, useState } from "react";
import { notification } from 'antd';
import "./Edu.css";
import userPng from "./users.png"
import Progress from "./progress/Progress";
import Box from "../../../../components/box/Box";
import Graph from "../../../../components/Graph/Graph";
import Bargraph from "../../../../components/Graph/Bargraph";
import Attendance from "./Attendance/Attendance";
import { getStudentsEnrolledInCourse } from "../../../../service/getStudentsEnrolledInCourse";
import { updateTrainerEarnings } from "../../../../service/updateEarnings/updateTrainerEarnings";
import { useAuth } from "../../../../provider/Auth.provider";
import { getCoursesByTrainer } from "../../../../service/courses/getCoursesByTrainer";


const Edu = () => {
  const { user } = useAuth();
  const [showAttendance, setShowAttendance] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [studentsEnrolledInCourse, setStudentsEnrolledInCourse] = useState([]);
  const [singleCourseName, setSingleCourseName] = useState("")
  const [singleCourseModule, setSingleCourseModule] = useState("")


  const showAttendanceModal = async (courseId, courseName, moduleName) => {
    console.log("moduleName", moduleName, "courseName", courseName);

    setShowAttendance(true);
    setSingleCourseName(courseName);
    setSingleCourseModule(moduleName);

    try {
      const students = await getStudentsEnrolledInCourse(courseId);
      setStudentsEnrolledInCourse(students);
    } catch (error) {
      console.error('Failed to load students:', error);
    }
  };


  const closeAttendanceModal = () => {
    setShowAttendance(false);
  };

  const handleTrainerEarnings = async () => {
    const trainerId = user.uid;
    if (trainerId) {
      await updateTrainerEarnings(trainerId);
    } else {
      notification.success({
        message: 'Please login to update trainer earnings',
        description: `Successful`,
        placement: 'topRight',
        duration: 3,
      });
    }
  }


  useEffect(() => {
    async function fetchCourseData() {
      if (!user.uid) {
        console.error("Trainer UID is not available.");
        return;
      }
      const data = await getCoursesByTrainer(user.uid);
      setCourseData(data);
      console.log(data);
    }
    fetchCourseData();
  }, [user.uid]);


  return (
    <>
      <div className="edu">
        <h1 className="heading">Education & Progress</h1>
        <div className="boxes">
          <Box subhed={"User"} value={"40,689"} logo={userPng} />
          <Box subhed={"User"} value={"40,689"} logo={userPng} />
          <Box subhed={"User"} value={"40,689"} logo={userPng} />
          <Box subhed={"User"} value={"40,689"} logo={userPng} />
        </div>

        <div className="graphes">
          <Graph />
          <Bargraph />
        </div>


        <h2>Your coureses</h2>
        <div className="pro">
          <Progress
            data={courseData}
            onClickAttendance={showAttendanceModal}
          />
        </div>

      </div>


      {/* if showAttendanceModal = true */}
      <Attendance
        showAttendanceModal={showAttendance}
        closeAttendanceModal={closeAttendanceModal}
        singleCourseName={singleCourseName}
        singleCourseModule={singleCourseModule}
        studentsEnrolledInCourse={studentsEnrolledInCourse}
        handleTrainerEarnings={handleTrainerEarnings}
      />
    </>
  );
};

export default Edu;
