import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import Signin from './Signin/Signin';
import Dashboard from './Dashboard/Dashboard';
import Register from './Register/Register';
import { useAuth } from '../../provider/Auth.provider';
import { findUserByUID } from '../../service/findUsers/findUserByUID';

const TeamMember = () => {
  const { user, loading } = useAuth();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsRegistering(searchParams.get('action') === 'register');
  }, [location.search]);

  useEffect(() => {
    const checkUserAccess = async () => {
      try {
        if (!user?.uid) {
          notification.error({
            message: 'Credentials not found',
            description: 'Login to access dashboard',
            placement: 'topRight',
            duration: 3,
          });
          setIsSignedIn(false);
          return;
        }
        const userDetails = await findUserByUID(user.uid);
        if (userDetails?.userTypes === 'team_member') {
          setIsSignedIn(true);
          navigate('/teammember/dashboard', { replace: true });
          notification.success({
            message: 'Sign in confirmed',
            description: `UserType: ${userDetails.userTypes}`,
            placement: 'topRight',
            duration: 3,
          });
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'An error occurred while checking user access',
          placement: 'topRight',
          duration: 3,
        });
        setIsSignedIn(false);
      }
    };

    checkUserAccess();
  }, [user, navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {!isSignedIn ? (
        isRegistering ? <Register /> : <Signin />
      ) : (
        <Dashboard />
      )}
    </>
  );
};

export default TeamMember;
