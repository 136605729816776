import { useState } from 'react';
import { PiStarThin } from "react-icons/pi";
import styles from './FeedbackReview.module.css';
import ButtonComponent from '../../CssComponents/ButtonComponent/ButtonComponent';
import closeIcon from "./close.png"
import TextArea from 'antd/es/input/TextArea';

const FeedbackReview = ({ showModal, setShowModal }) => {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({
            rating,
            comment,
        });
    };

    return (

        <div className={showModal ? `${styles.modal} ${styles.displayBlock}` : `${styles.modal} ${styles.displayNone}`}>
            <form className={styles.container} onSubmit={handleSubmit}>

                <div className={styles.heading}>Feedback Section</div>
                <button
                    className={styles.closeBtn}
                    onClick={() => setShowModal(false)}
                >
                    <img src={closeIcon} alt="Close" />
                </button>


                <div className={styles.wrapperContainer}>
                    <div htmlFor="comment" className={styles.labelText}>Leave your feedback</div>
                    <TextArea
                        type="text"
                        id="comment"
                        className={styles.inputTextArea}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Enter your comments here"
                    />
                </div>

                <div className={styles.wrapperContainer}>
                    <div className={styles.labelText}>Rate us</div>
                    <div className={styles.stars}>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <PiStarThin
                                key={star}
                                className={`${styles.star} ${star <= rating ? styles.active : ''}`}
                                onClick={() => setRating(star)}
                            />
                        ))}
                    </div>
                </div>

                <ButtonComponent
                    buttonType='submit'
                    buttonText={"Submit Your Review"}
                />
            </form>
        </div>
    );
};

export default FeedbackReview;
