import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase-config";
/**
 * Save a transaction to Firestore.
 * @param {string} userId - The unique identifier of the user making the transaction.
 * @param {number} amount - The payment amount.
 * @param {string} proofUrl - The URL of the uploaded payment proof.
 * @param {string} reason - The reason or description of the transaction.
 * @returns {Promise<void>} - Resolves when the transaction is saved.
 */
export const saveTransaction = async (userId, amount, proofUrl, reason) => {
  try {
    const now = new Date(); // Get current date

    const transactionData = {
        id: userId, // User's unique identifier
        amount, // Transaction amount
        day: now.getDate(), // Extracts day (1-31)
        month: now.getMonth() + 1, // Extracts month (0-11) and adjusts to (1-12)
        year: now.getFullYear(), // Extracts year (e.g., 2024)
        proofUrl, // Proof of transaction URL
        reason, // Reason for the transaction
    };

    await addDoc(collection(db, "transactions"), transactionData);

    console.log("Transaction saved successfully:", transactionData);
  } catch (error) {
    console.error("Error saving transaction:", error);
    throw error; // Re-throw the error for higher-level handling
  }
};



/**
 * Save a payment for a user in Firestore under the user's payment subcollection.
 * @param {string} userId - The unique identifier of the user.
 * @param {number} amount - The payment amount.
 * @param {string} proofUrl - The URL of the uploaded payment proof.
 * @returns {Promise<void>} - Resolves when the payment is saved.
 */
export const savePayment = async (userId, amount, proofUrl) => {
  try {
    // Get current date components
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1; // Months are 0-indexed, so add 1
    const year = now.getFullYear();

    const paymentData = {
      amount, // Payment amount
      day, // Day of the payment
      month, // Month of the payment
      year, // Year of the payment
      proofUrl, // Proof of payment URL
    };

    // Save to the user's payment subcollection
    const userPaymentRef = collection(db, `users/${userId}/payment`);
    await addDoc(userPaymentRef, paymentData);

    console.log("Payment saved successfully:", paymentData);
  } catch (error) {
    console.error("Error saving payment:", error);
    throw error; // Re-throw the error for higher-level handling
  }
};
