// TermsAndConditions.rejs
import React, { useEffect, useState } from "react";
import styles from "./RefundPolicy.module.css";
import Header from "../landing/header/Header";
import Footer from "../landing/Footer/Footer";
import HeaderLoginOptions from "../components/LandingComponents/HeaderLoginOptions/HeaderLoginOptions";
import HeaderRegisterOptions from "../components/LandingComponents/HeaderRegisterOptions/HeaderRegisterOptions";

const RefundPolicy = () => {
  useEffect(() => {
    const originalStyles = {
      fontFamily: document.body.style.fontFamily,
      margin: document.body.style.margin,
      padding: document.body.style.padding,
      boxSizing: document.body.style.boxSizing,
      outline: document.body.style.outline,
      border: document.body.style.border,
      textDecoration: document.body.style.textDecoration,
      fontSize: document.documentElement.style.fontSize,
      overflowX: document.documentElement.style.overflowX,
      scrollBehavior: document.documentElement.style.scrollBehavior,
      scrollPaddingTop: document.documentElement.style.scrollPaddingTop,
    };

    // Apply global styles
    document.body.style.fontFamily = '"DM Sans", sans-serif';
    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.boxSizing = "border-box";
    document.body.style.outline = "none";
    document.body.style.border = "none";
    document.body.style.textDecoration = "none";
    document.documentElement.style.fontSize = "62.5%";
    document.documentElement.style.overflowX = "hidden";
    document.documentElement.style.scrollBehavior = "smooth";
    document.documentElement.style.scrollPaddingTop = "7rem";

    return () => {
      // Cleanup global styles
      document.body.style.fontFamily = originalStyles.fontFamily;
      document.body.style.margin = originalStyles.margin;
      document.body.style.padding = originalStyles.padding;
      document.body.style.boxSizing = originalStyles.boxSizing;
      document.body.style.outline = originalStyles.outline;
      document.body.style.border = originalStyles.border;
      document.body.style.textDecoration = originalStyles.textDecoration;
      document.documentElement.style.fontSize = originalStyles.fontSize;
      document.documentElement.style.overflowX = originalStyles.overflowX;
      document.documentElement.style.scrollBehavior = originalStyles.scrollBehavior;
      document.documentElement.style.scrollPaddingTop = originalStyles.scrollPaddingTop;
    };
  }, []);

  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [activeHash, setActiveHash] = useState(window.location.hash || "#home");
    const [isHovered, setIsHovered] = useState(false);

    const openRegister = () => {
        setIsRegisterOpen(true);
    };

    const closeRegister = () => {
        setIsRegisterOpen(false);
    };

    const openLogin = () => {
        setIsHovered(false)
        setIsLoginOpen(true);
    };

    const closeLogin = () => {
        setIsLoginOpen(false);
    };

    // Update `activeHash` when URL fragment changes
    useEffect(() => {
        const handleHashChange = () => {
            setActiveHash(window.location.hash || "#home");
        };

        // Listen for hash changes
        window.addEventListener("hashchange", handleHashChange);

        // Cleanup event listener
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

  return (
    <>
    <HeaderLoginOptions isLoginOpen={isLoginOpen} closeLogin={closeLogin} />
    <HeaderRegisterOptions isRegisterOpen={isRegisterOpen} closeRegister={closeRegister} />
      <Header 
      activeHash={activeHash}
      openLogin={openLogin}
      openRegister={openRegister}
      isHovered={isHovered}
      setIsHovered={setIsHovered}
      />
      <div className={styles.refundContainer}>
        <h1 className={styles.heading}>Refund Policy</h1>
        <div className={styles.content}>
          <p>
            At [Your Company Name], we strive to provide the best services to our
            customers. However, if you are not satisfied with your purchase, we
            are here to help.
          </p>
          <h2>Refunds</h2>
          <p>
            Refund requests can be made within <strong>30 days</strong> of the
            original purchase date. To be eligible for a refund, the following
            conditions must be met:
          </p>
          <ul>
            <li>The product or service must be unused and in the same condition as received.</li>
            <li>You must provide a valid receipt or proof of purchase.</li>
            <li>Refunds are not applicable for digital products once they have been downloaded.</li>
          </ul>
          <h2>Non-Refundable Items</h2>
          <p>The following items are non-refundable:</p>
          <ul>
            <li>Gift cards</li>
            <li>Downloadable software products</li>
            <li>Services that have already been rendered</li>
          </ul>
          <h2>How to Request a Refund</h2>
          <p>
            To request a refund, please contact our customer support team at
            <a href="mailto:support@yourcompany.com" className={styles.link}>
              support@yourcompany.com
            </a>
            . Provide your order details and the reason for your refund request.
            We will process your request within <strong>7-10 business days</strong>.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about our refund policy, please contact us
            at
            <a href="mailto:support@yourcompany.com" className={styles.link}>
              support@yourcompany.com
            </a>
            .
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
