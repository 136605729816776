import React from 'react'
import close from "./close.png"
import styles from "./userNotification.module.css"

function UserNotification({ showModal, closeModal, sampleNotificationData }) {
    return (
        <div
            className={showModal ? `${styles.modal} ${styles.displayBlock}` : `${styles.modal} ${styles.displayNone}`}
        >
            <div className={styles.modalMain}>
                <div
                    className={styles.closebtn}
                    onClick={closeModal}>
                    <img src={close} alt="" />
                </div>
                <div className={styles.mainContent}>
                    {
                        sampleNotificationData && sampleNotificationData.map((item) => (
                            <div
                                key={item.id}
                                className={styles.notifyWrapper}
                            >
                                <div> {item.message}</div>
                                <div> {item.type} </div>
                                <div> {item.time} </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default UserNotification