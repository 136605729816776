import React, { useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { notification } from 'antd';
import styles from './CertificatePage.module.css';
import certImg from "./certImg.png";
import ButtonComponent from '../../../../components/CssComponents/ButtonComponent/ButtonComponent';
import Certificate from '../../../../components/CssComponents/Certificate/certificate';
import { getCoursesOfUser } from '../../../../service/courses/getCoursesOfUser';

const CertificatePage = ({ userData }) => {
  const certificateRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [coursesCertificate, setCoursesCertificate] = useState([])

  useEffect(() => {
    const fetchCoursesData = async () => {
      if (!userData || !userData?.courseIdsArray || userData?.courseIdsArray.length <= 0) {
        return;
      }
      const coursesOfUser = await getCoursesOfUser(userData?.courseIdsArray);
      const certificateData = coursesOfUser.map((course) => {
        return {
          recipientName: `${userData.firstName + " " + userData.lastName}` || 'Anurag Affection',
          date: course.endDate,
          courseName: course.courseName,
          signature: 'Affiliate Ritual',
          textTitle: `${userData.firstName + " " + userData.lastName} has completed the ${course.courseName} course`,
          text: `This course includes ${course.courseDuration} months of training, ${course.numModules} modules, and was conducted by ${course.selectedTrainer || 'a certified trainer'}.`,
        };
      });
      setCoursesCertificate(certificateData);
    };

    fetchCoursesData();
  }, [userData]);

  console.log(coursesCertificate)

  const downloadPDF = async () => {
    const element = certificateRef.current;
    if (!element) {
      console.error("Certificate element is not available.");
      return;
    }

    try {
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true, // Enable cross-origin resource sharing if needed
        backgroundColor: '#fff', // Ensure a solid background color
        imageTimeout: 5000,
      });

      const imgData = canvas.toDataURL('image/png');
      const pdfWidth = 950;
      const pdfHeight = (canvas.height / canvas.width) * pdfWidth;

      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [pdfWidth, pdfHeight],
      });

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`certificate.pdf`);
      notification.success({
        message: 'Certificate Downloaded',
        description: `Certificate downloaded successfully`,
        placement: 'topRight',
        duration: 3,
      });
    } catch (error) {
      notification.error({
        message: 'Try Downloading in desktop',
        description: `${error.message}`,
        placement: 'topRight',
        duration: 3,
      });
      console.error("Error generating PDF:", error);
    }
    finally {
      setSelectedItem(null);
    }
  };


  return (
    <div className={styles.certificate}>
      
      <h1 className={styles.certificateHeading}>Certificates</h1>

      { coursesCertificate.length > 0 && coursesCertificate.map((item, index) => (
        <div key={index} className={styles.certificateWrapper}>
          {/* Render Certificate img for preview */}
          <img
            src={certImg}
            alt="certificate"
            className={styles.certificateImg}
          />
          {/* Download button */}
          <ButtonComponent
            className={styles.btn}
            onClick={() => {
              setSelectedItem(item);
              // Update the selected item
              setTimeout(() => downloadPDF(), 0);
              // Allow the DOM to update before generating PDF
            }}
            buttonText={`${item.courseName}`}
          />
        </div>
      ))}

      {/* Hidden Certificate Component */}
      <div
        className={styles.certificatePreview}
        ref={certificateRef}
      >
        {selectedItem && <Certificate certificateData={selectedItem} />}
      </div>
    </div>
  );
};

export default CertificatePage;
