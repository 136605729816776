import styles from './Certificate.module.css'
import ArLogo from "../ArLogo/ArLogo"
import certificateSidebar from "./certificateSidebar.png"
import certificateLogo from "./certificateLogo.png"

export default function Certificate({certificateData}) {
    return (
        <div className={styles.certificate}>

            <div className={styles.certificateSidebar}>
                <img
                    src={certificateSidebar}
                    className={styles.certificateImg}
                    alt='polygon img'
                />
            </div>

            <div className={styles.mainContent}>
                <div className={styles.contentWrapper}>

                    <div className={styles.logoWrapper}>
                        <ArLogo />
                        <img
                            src={certificateLogo}
                            className={styles.certificateLogo}
                            alt='certificate logo'
                        />
                    </div>

                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>Certificate</div>
                        <div className={styles.subtitle}>of appreciation</div>
                    </div>

                    <div className={styles.presentedWrapper}>
                        <div className={styles.presentedText}>This Certificate is Presented to </div>
                        <div className={styles.recipient}>{certificateData.recipientName}</div>
                    </div>

                    <div className={styles.textWrapper}>
                        <div className={styles.textTitle}>{certificateData.textTitle}</div>
                        <div className={styles.text}>{certificateData.text}</div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.dateWrapper}>
                        <div className={styles.date}>{certificateData.date}</div>
                        <div className={styles.line} />
                        <div className={styles.label}>Date</div>
                    </div>
                    <div className={styles.signatureWrapper}>
                        <div className={styles.signature}>{certificateData.signature}</div>
                        <div className={styles.line} />
                        <div className={styles.label}>Signature</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

