import React, { useEffect, useState } from 'react'
import Table from './Table/Table'
import "./direct.css"
import { findUsersUsingMyARID } from '../../../../service/findUsers/findUsersUsingMyARID';

const Direct = ({ userData }) => {
  const [referredUsers, setReferredUsers] = useState(null);

  useEffect(() => {
    const fetchUsersUsingMyARID = async () => {
      if (userData?.myARID) {
        const usersUsingMyARID = await findUsersUsingMyARID(userData.myARID);
        setReferredUsers(usersUsingMyARID);
      }
    }
    fetchUsersUsingMyARID();
  }, [userData.myARID]);

  return (
    <div className='Direct'>
      <h1 className="heading">Direct Sale Report</h1>
      <div className="containe"><h2 className="subheading">Active Students</h2> <input placeholder='Search' className='input' type="text" /></div>
      <div className="flex">
        <Table data={referredUsers} onViewFull={() => { }} showAction={false} />
      </div>

      <div className="containe"><h2 className="subheading">In-Active Students</h2> <input placeholder='Search' className='input' type="text" /></div>
      <div className="flex">
        <Table data={referredUsers} onViewFull={() => { }} showAction={false} />
      </div>

      <div className="containe"><h2 className="subheading">Passed Out Students</h2> <input placeholder='Search' className='input' type="text" /></div>
      <div className="flex">
        <Table data={referredUsers} onViewFull={() => { }} showAction={false} />
      </div>
    </div>
  )
}

export default Direct