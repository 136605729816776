import React, { useState, useEffect } from "react";

import "./Payout.css";
import user from "./users.png";

import Box from "../../../../components/box/Box";
import Bargraph from "../../../../components/Graph/Bargraph";
import Smallbar from "../../../../components/Smallbar/Smallbar";
import PieChart from "../../../../components/Pie/PieChart";
import Pay from "../../../../components/Pay/Pay.js"

import Table from "./Table/Table.js";

import { getClubTrainersEarnings } from "../../../../service/getUsersEarnings/getClubTrainersEarnings.js"
import { getTeamLeadersEarnings } from "../../../../service/getUsersEarnings/getTeamLeadersEarnings.js"
import { getTeamMembersEarnings } from "../../../../service/getUsersEarnings/getTeamMembersEarnings.js"

const Payout = () => {
  const [showPay, setShowPay] = useState(false);
  const [userPayInfo, setUserPayInfo] = useState(null);
  const [selectedId, setSelectedId] = useState(null); // ✅ Added selectedId

  const [displayCount, setDisplayCount] = useState(5);

  const [teamLeadersData, setTeamLeadersData] = useState([]);
  const [teamMembersData, setTeamMembersData] = useState([]);
  const [clubTrainersData, setClubTrainersData] = useState([]);

  // ✅ Update to store selected user data & ID when pay button is clicked
  const showPayModal = (item) => {
    setSelectedId(item.id); // ✅ Store the selected user's ID
    setUserPayInfo(item);
    setShowPay(true);
  };

  const closePayModal = () => {
    setShowPay(false);
    setUserPayInfo(null);
    setSelectedId(null); // ✅ Reset selectedId on close
  };

  const handleLoadMore = () => {
    setDisplayCount(displayCount + 5);
  };

  useEffect(() => {
    const fetchEarnings = async () => {
      const teamLeadersEarnings = await getTeamLeadersEarnings();
      setTeamLeadersData(teamLeadersEarnings);

      const membersEarnings = await getTeamMembersEarnings();
      setTeamMembersData(membersEarnings);

      const trainerEarnings = await getClubTrainersEarnings();
      setClubTrainersData(trainerEarnings);
      console.log("trainerEarnings",trainerEarnings);
      
    };

    fetchEarnings();
  }, []);

  return (
    <div className="Payout">
      <h1 className="heading">Payout</h1>
      {/* <div className="boxes">
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
      </div>
      <div className="graphs">
        <Smallbar />
        <PieChart data={[10, 20, 30]} labels={["Red", "Blue", "Yellow"]} />
        <Bargraph />
      </div> */}

      <div className="tablespayout">
        <div className="sl">
          <h1 className="heading">Trainer</h1>
          <h1 className="viw">View All</h1>
        </div>
        <div className="tb">
          <Table
            data={clubTrainersData.slice(0, displayCount)}
            onClickPay={showPayModal} // ✅ Pass showPayModal function
          />
        </div>

        <div className="sl">
          <h1 className="heading">Club Leader</h1>
          <h1 className="viw">View All</h1>
        </div>
        <div className="tb">
          <Table
            data={teamLeadersData.slice(0, displayCount)}
            onClickPay={showPayModal}
          />
        </div>

        <div className="sl">
          <h1 className="heading">Club Member</h1>
          <h1 className="viw">View All</h1>
        </div>
        <div className="tb">
          <Table
            data={teamMembersData.slice(0, displayCount)}
            onClickPay={showPayModal}
          />
        </div>
      </div>

      {/* ✅ Pass selectedId to the Pay component */}
      <Pay
        showPayModal={showPay}
        closePayModal={closePayModal}
        userPayInfo={userPayInfo}
        selectedId={selectedId} // ✅ Added selectedId as prop
      />
    </div>
  );
};

export default Payout;
