import React, { useState, useEffect } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { doc, setDoc, getDoc, getDocs, collection, where, query } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Select from 'react-select'; // Import react-select
import styles from "../../../styles/Register.module.css"
import OtpInputContainer from "../../../components/FlowComponents/OtpInputContainer/OtpInputContainer.jsx";
import { Link, useLocation } from "react-router-dom";
import { auth, db } from "../../../firebase-config.js";
import { findCoursePriceById, findCourseRegistrationPriceById } from "../../../service/findCoursePriceById.js";
import { updateUserEarnings } from "../../../service/updateEarnings/updateUserEarnings.js";
import { getCurrentTimestamp } from "../../../service/time/getCurrentTimestamp.js";
import { notification } from "antd";
import ButtonComponent from "../../../components/CssComponents/ButtonComponent/ButtonComponent.jsx";
import PhoneInputComponent from "../../../components/CssComponents/PhoneInput/PhoneInputComponent.jsx";
import ArLogo from "../../../components/CssComponents/ArLogo/ArLogo.jsx";
import RegisterLogo from "../../../components/CssComponents/RegisterLogo/RegisterLogo.jsx";
import { findUserWhoReferedMe } from "../../../service/findUsers/findUserWhoReferedMe.js";
import { saveTransaction } from "../../../service/saveTransactions.js";


// const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const StudentRegister = () => {
  // Fetch courses from Firestore
  const location = useLocation(); // Get the current location object
  const [otp, setOtp] = useState('');
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(false)
  const [helpText, setHelpText] = useState('')
  const [uid, setUid] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };


  const [formData, setFormData] = useState({
    isActive: false,
    firstName: "",
    lastName: "",
    phone: "",
    courseId: "",
    courseIdsArray: [], // Updated to match the key
    dateOfBirth: "",
    aadharNumber: "",
    panNumber: "",
    address: "",
    accountNumber: "",
    accountType: "",
    ifscCode: "",
    referralId: "",
    userTypes: "team_leader",
    myARID: "",
    createdAt: "",
    updatedAt: "",
  });

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        console.log('reCAPTCHA solved');
      }
    });

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);



  // Extract course ID from URL query params
  const getCourseIdFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("course") || ""; // Default to an empty string if not provided
  };

  useEffect(() => {
    // Fetch courses from Firestore
    const fetchCourses = async () => {
      try {
          const courseCollection = collection(db, "courses");
  
          // ✅ Firestore Query: Fetch only published courses (`publish: true`)
          const q = query(courseCollection, where("publish", "==", true));
          const courseSnapshot = await getDocs(q);
  
          // ✅ Map results
          const courseList = courseSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          
          setCourses(courseList);
  
          // ✅ Check if a `courseId` is provided in the URL
          const courseId = getCourseIdFromUrl();
          if (courseId) {
              setFormData((prevData) => ({
                  ...prevData,
                  courseId: courseId,
                  courseIdsArray: [courseId], // Initialize with the selected course ID
              }));
          }
      } catch (error) {
          console.error("Error fetching published courses:", error);
      }
  };
    fetchCourses();
  }, [location.search]);

  const handleCourseChange = (selectedOption) => {
    setFormData((prevData) => {
      // Check if courseId is already in courseIdsArray
      const isCourseIdPresent =
        prevData.courseIdsArray &&
        prevData.courseIdsArray.some((course) => course.courseId === selectedOption.value);

      return {
        ...prevData,
        courseId: selectedOption ? selectedOption.value : '',
        courseIdsArray: isCourseIdPresent
          ? prevData.courseIdsArray // Do not modify if courseId already exists
          : [
            ...(prevData.courseIdsArray || []), // Keep existing courses
            {
              courseId: selectedOption.value,
              status: "pending", // Default status
              registrationImage: prevData.registrationImage || "", // Include registrationImage if available
            },
          ],
      };
    });
  };


  const courseOptions = courses.map(course => ({
    value: course.id,
    label: course.courseName,
  }));


  const handleOtpChange = (otpComing) => {
    setOtp(otpComing);
  };

  const generateMyARID = () => {
    const randomNumbers = Math.floor(100000 + Math.random() * 900000);
    return `TL${randomNumbers}`;
  };

  const sendVerificationCode = (e) => {
    e.preventDefault();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, formData.phone, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        notification.success({
          message: 'OTP Sent',
          description: 'OTP sent to your phone',
          placement: 'topRight',
          duration: 3, // Display for 3 seconds
        });

      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
        notification.error({
          message: 'OTP Sending Failed',
          description: 'Failed to send OTP. Please try again.',
          placement: 'topRight',
          duration: 3, // Display for 3 seconds
        });

      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (!confirmationResult) {
      notification.info({
        message: 'OTP Request Needed',
        description: 'First request the OTP',
        placement: 'topRight',
        duration: 3, // Display for 3 seconds
      });

      return;
    }
    confirmationResult.confirm(otp)
      .then(async (result) => {
        // auth user 
        const { user } = result;
        setUid(user.uid);
        notification.success({
          message: 'Verification Successful',
          description: `Phone number verified! User: ${user.uid}`,
          placement: 'topRight',
          duration: 3,
        });

        // Check if the user data already exists
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          // If user data exists, use it and prevent overwriting myARID
          // fetching the data if exists & updating formData
          const existingData = userDocSnapshot.data();
          setFormData(existingData);
        } else {
          const createdAt = getCurrentTimestamp();
          // If user data does not exist, generate a new myARID
          const myARID = generateMyARID();
          setFormData(prevData => ({ ...prevData, myARID, createdAt }));
        }
      })
      .catch((error) => {
        console.error('Error verifying OTP:', error);
        notification.error({
          message: 'Verification Failed',
          description: 'Failed to verify OTP. Please try again.',
          placement: 'topRight',
          duration: 3,
        });
      });
  };

  const addUserToFirestore = async (coursePrice) => {
    try {
      let updatedFormData = { ...formData, uid };

      if (selectedFile) {
        // Initialize Firebase storage
        const storage = getStorage();
        const storageRef = ref(storage, `images/student/${uid.slice(0, 5)}/${selectedFile.name}`);

        // Upload the file
        const snapshot = await uploadBytes(storageRef, selectedFile);

        // Get the download URL
        const downloadURL = await getDownloadURL(snapshot.ref);

        // Update the formData with the download URL
        updatedFormData = {
          ...updatedFormData,
          registrationImage: downloadURL,
          courseIdsArray: updatedFormData.courseIdsArray.map((course) => ({
            ...course,
            registrationImage: downloadURL, // Add registrationImage to each course object
          })),
        };
        await saveTransaction(uid, coursePrice, downloadURL, "User registration")

      } else {
        // If no file is selected, ensure course objects still include an empty registrationImage
        updatedFormData = {
          ...formData,
          uid: uid,
        };
        notification.error({
          message: "Error while register",
          description: "Please attach payment proof",
          placement: 'topRight',
          duration: 3
        })
      }

      // Save the updated data to Firestore
      await setDoc(doc(db, "users", uid), updatedFormData, { merge: true });

      notification.success({
        message: 'Registration Successful',
        description: 'User data saved to Firestore!',
        placement: 'topRight',
        duration: 3,
      });
    } catch (error) {
      console.error("Error adding user to Firestore: ", error);

      notification.error({
        message: 'Registration Error',
        description: `Error adding user to Firestore: ${error.message}`,
        placement: 'topRight',
        duration: 3,
      });
    }
  };




  // handle input changes 
  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "referralId") {
      setFormData({ ...formData, [name]: value });

      const foundUser = await findUserWhoReferedMe(value)
      console.log(foundUser);

      if (foundUser) {
        setError(false)
        setHelpText(`User found ${foundUser.firstName} ${foundUser.lastName}`)
      } else {
        setError(true)
        setHelpText("No User found, You got Wrong Referral ID")
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const updatePhoneNumber = (phoneNumber) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: phoneNumber,
    }));
  };



  const handleRegister = async (e) => {
    e.preventDefault();

    const updatedAt = getCurrentTimestamp();
    setFormData({ ...formData, updatedAt })
    let coursePrice = 0
    // Check if referral ID is provided, exits is our database or not 
    if (formData.referralId && formData.courseId && uid) {
      // do the money distribution things
      // add type checking here
      const referringUserId = await findUserWhoReferedMe(formData.referralId);
      coursePrice = await findCourseRegistrationPriceById(formData.courseId);
      if (referringUserId == null || coursePrice == null) {

        notification.warning({
          message: 'Invalid Input',
          description: 'Invalid referral ID or course ID',
          placement: 'topRight',
          duration: 3, // Display for 3 seconds
        });

      }

      if (referringUserId !== null) {
        updateUserEarnings(referringUserId, coursePrice, uid)
      }
    }

    // If referral ID is valid or not provided, still proceed with the registration
    if (uid) {
      addUserToFirestore(coursePrice);
    } else {
      notification.warning({
        message: 'Verification Required',
        description: 'Please verify OTP first',
        placement: 'topRight',
        duration: 3, // Display for 3 seconds
      });

    }
  };

  return (
    <>
      <div className={styles.RegisterBackground}>
        <div className={styles.RegisterContainer}>

          <div className={styles.RegisterImage}>
            <RegisterLogo />
          </div>

          <div className={styles.formSection}>
            <ArLogo />
            <div className={styles.headingText}>Student Registration</div>
            <form className={styles.formContainer}>

              <div id="recaptcha-container"></div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Phone Number <sup>*</sup></div>
                <PhoneInputComponent
                  value={formData.phone}
                  onChange={updatePhoneNumber}
                />
              </div>

              <ButtonComponent
                onClick={sendVerificationCode}
                className={""}
                buttonText={"Send OTP"}
              />

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Enter Otp <sup>*</sup></div>
                <OtpInputContainer onOtpChange={handleOtpChange} />
              </div>

              <ButtonComponent
                onClick={verifyOtp}
                className={""}
                buttonText={"Verify OTP"}
              />

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>First Name <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  required
                />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Last Name <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Enter last name"
                  required
                />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Date of Birth <sup>*</sup></div>
                <input
                  type="date"
                  className={styles.input}
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Aadhar Number <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="aadharNumber"
                  value={formData.aadharNumber}
                  onChange={handleChange}
                  placeholder="Aadhar Number"
                  required />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Pan Number <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="panNumber"
                  value={formData.panNumber}
                  onChange={handleChange}
                  placeholder="Pan Number" required />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Address <sup>*</sup></div>
                <input type="text" className={styles.input} name="address" value={formData.address} onChange={handleChange} placeholder="Address" required />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Select a course <sup>*</sup></div>
                <Select
                  className={styles.selectInput}
                  name="courseId"
                  options={courseOptions}
                  onChange={handleCourseChange}
                  placeholder="Select a course"
                  isSearchable={true}
                  value={courseOptions.find(option => option.value === formData.courseId)}
                  required
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: '#000',
                      borderColor: state.isFocused ? '#000' : '#000',
                      borderRadius: '8px',
                      boxShadow: state.isFocused ? '0 0 0 1px #000' : 'none',
                      '&:hover': {
                        borderColor: '#000'
                      }
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#fff'
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '#ccc'
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#fff'
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: '#000',
                      borderRadius: '8px',
                      borderColor: '#fff'

                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? '#333' : '#000',
                      color: state.isSelected ? '#fff' : '#ccc',
                      '&:hover': {
                        backgroundColor: '#555',
                        color: '#fff'
                      }
                    })
                  }}
                />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Referral ID</div>
                <input
                  type="text"
                  className={styles.input}
                  name="referralId"
                  value={formData.referralId}
                  onChange={handleChange}
                  placeholder="Enter Referral ID"
                />
              </div>

              <div style={{ color: error ? 'red' : 'green' }}>{helpText}</div>

              <div className={styles.headingText}>Bank Details</div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Account Number <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  placeholder="Account Number"
                  required
                />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Account Type <sup>*</sup></div>
                <input type="text" className={styles.input} name="accountType" value={formData.accountType} onChange={handleChange} placeholder="Account Type" required />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Ifsc Code <sup>*</sup></div>
                <input type="text" className={styles.input} name="ifscCode" value={formData.ifscCode} onChange={handleChange} placeholder="Ifsc Code" required />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Upload Payment proof <sup>*</sup></div>
                <input type="file" required={true} onChange={handleFileChange} />
              </div>

              <ButtonComponent
                onClick={handleRegister}
                className={""}
                buttonText={"Register"}
              />

              <Link
                to="/student?action=login"
                style={{
                  textDecoration: 'none',
                }}
              >
                <div className={styles.alr}>already a member? <span>Login</span></div>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentRegister;
