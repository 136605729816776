import React from "react";
import styles from "../Header.module.css";
import HeaderButtons from "./HeaderButtons";
import { useLocation } from "react-router-dom";

const DesktopNav = ({ toggleNavbar, activeHash, openLogin, openRegister, isHovered, setIsHovered }) => {
    const location = useLocation();

    return (
        <div className={styles.desktopView}>
            <nav className={`${styles.navbar}`}>
                <a
                    className={activeHash === "#home" && location.pathname !== "/contact-us" ? styles.activeLink : ""}
                    href="/#home"
                >
                    Home
                </a>
                <a
                    className={activeHash === "#product" ? styles.activeLink : ""}
                    href="/#product">
                    Product
                </a>

                <a
                    className={activeHash === "#leaderboard" ? styles.activeLink : ""}
                    href="/#leaderboard"
                >
                    Leaderboard
                </a>
                <a
                    className={location.pathname === "/contact-us" ? styles.activeLink : ""}
                    href="/contact-us">
                    Contact Us
                </a>
            </nav>
            <HeaderButtons
                toggleNavbar={toggleNavbar}
                openLogin={openLogin}
                openRegister={openRegister}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
            />
        </div>
    );
};

export default DesktopNav;
