import React, { useState, useRef, useEffect } from 'react';
import styles from "./userProfile.module.css";
import closeIcon from "./close.png";
import ButtonComponent from '../../CssComponents/ButtonComponent/ButtonComponent';
import { updateUserData } from '../../../service/updateUserData';
import { useAuth } from '../../../provider/Auth.provider';


function UserProfile({ userData: initialUserData, showModal, closeModal }) {

    console.log("initialUserData", initialUserData);

    const { user } = useAuth()
    const fileInputRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [file, setFile] = useState(null);

    const [userData, setUserData] = useState({
        ...initialUserData,
        photoUrl: initialUserData?.photoUrl || "https://logo.gklite.in/anuragaffection.jpg",
    });


    useEffect(() => {
        setUserData(initialUserData);
    }, [initialUserData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile); // Store the selected file for uploading later.
            const reader = new FileReader();
            reader.onloadend = () => {
                setUserData(prevData => ({
                    ...prevData,
                    photoUrl: reader.result, // Show a preview of the image.
                }));
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsEditing(false);
            // we are not storing uid in each of userData, 
            // todo: making data storage consistence
            const updatedData = await updateUserData(user.uid, userData, file);
            setUserData(updatedData); // Update state with the saved data.
            setFile(null); // Reset the file state.
            console.log("User data updated successfully:", updatedData);
        } catch (error) {
            console.error("Error updating user data:", error);
        }
    };

    return (
        <div className={showModal ? `${styles.modal} ${styles.displayBlock}` : `${styles.modal} ${styles.displayNone}`}>
            <div className={styles.modalMain}>
                <button className={styles.closeBtn} onClick={() => {
                    setIsEditing(false);
                    closeModal();
                }}>
                    <img src={closeIcon} alt="Close" />
                </button>
                <div className={styles.mainContent}>
                    {
                        isEditing ? (
                            <form onSubmit={handleSubmit} className={styles.form}>
                                <div className={styles.imageUpload}>
                                    <img
                                        src={userData.photoUrl}
                                        alt={userData.firstName}
                                        className={styles.profilePhoto}
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                    />
                                    <ButtonComponent
                                        onClick={() => fileInputRef.current.click()}
                                        buttonText={"Update Photo"}
                                    />
                                </div>

                                <div className={styles.labelInputWrapper}>
                                    <div className={styles.labelText}>First Name</div>
                                    <input
                                        type="text"
                                        className={styles.input}
                                        name="firstName"
                                        value={userData.firstName}
                                        onChange={handleChange}
                                        placeholder="Enter first name"
                                        required
                                    />
                                </div>
                                <div className={styles.labelInputWrapper}>
                                    <div className={styles.labelText}>Last Name</div>
                                    <input
                                        type="text"
                                        className={styles.input}
                                        name="lastName"
                                        value={userData.lastName}
                                        onChange={handleChange}
                                        placeholder="Enter last name"
                                        required
                                    />
                                </div>
                                <ButtonComponent buttonType="submit" buttonText={"Save Changes"} />
                            </form>
                        ) : (
                            <div className={styles.profileInfo}>
                                <img
                                    src={userData?.photoUrl}
                                    alt={userData?.firstName}
                                    className={styles.profilePhoto}
                                />
                                <div
                                    className={styles.username}
                                >
                                    {userData?.firstName + " " + userData?.lastName}
                                </div>
                                <ButtonComponent
                                    buttonType="button"
                                    onClick={() => setIsEditing(true)}
                                    buttonText={"Edit Profile"}
                                />
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
}

export default UserProfile;
