import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase-config";
import { notification } from "antd";
import styles from '../../../styles/DashboardStyles/Dashboard.module.css';
import logout from "../../../service/logout.js";

import Sidebar from "./Sidebar.jsx";
import Header from "./Header.jsx";
import Edu from "./EduProg/Edu.js"
import Certificate from "./CertificatePage/CertificatePage.js"
// import Certificate from "../../../components/CssComponents/Certificate/certificate.jsx";

import Logout from "../../../components/LogoutModal/Logout";
import { getCoinsOfUser } from "../../../service/coins/getCoinsOfUser";
import UserNotification from "../../../components/FlowComponents/userNotification/UserNotification.jsx";
import UserProfile from "../../../components/FlowComponents/userProfile/UserProfile.jsx";
import { sampleNotificationData } from "../../../service/notificationService/sampleNotificationData.js";
import { useAuth } from "../../../provider/Auth.provider.jsx";
import { findUserByUID } from "../../../service/findUsers/findUserByUID.js";
import CoursePackage from "./CoursePackage/CoursePackage.jsx";

const Dashboard = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);

  const [activeElement, setActiveElement] = useState('education');
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await findUserByUID(user.uid);
      setUserData(userData);
    };
    fetchUserData();
  }, [user]);


  useEffect(() => {
    const handleResize = () => {
      setIsMenuExpanded(false);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleClick = (element) => setActiveElement(element);

  return (
    <div className={`${styles.dashboard}`}>


      <Sidebar
        activeElement={activeElement}
        handleClick={handleClick}
        openModal={() => setShowModal(true)}
        isMenuExpanded={isMenuExpanded}
      />


      <div className={styles.maincontent}>
        <Header
          userData={userData}
          openNotifyModal={() => setNotifyModal(true)}
          openProfileModal={() => setProfileModal(true)}
          toggleMenu={() => setIsMenuExpanded(!isMenuExpanded)}
        />
        {/* {activeElement === 'education' ? <Edu /> : <Certificate userData={userData} />} */}
        {activeElement === 'education' ? <Edu /> : null}
        {activeElement === 'certificate' ? <Certificate userData={userData} /> : null}
        {activeElement === 'coursePackage' ? <CoursePackage /> : null}
      </div>

      {/* this is modal, it will open when true, independent in itself */}
      <Logout
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        handleLogout={logout}
      />
      <UserNotification
        showModal={notifyModal}
        closeModal={() => setNotifyModal(false)}
        sampleNotificationData={sampleNotificationData}
      />
      <UserProfile
        userData={userData}
        showModal={profileModal}
        closeModal={() => setProfileModal(false)}
      />
    </div>
  );
};

export default Dashboard;
