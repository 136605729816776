import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import styles from './Trainer.module.css'; // Import the CSS Module
import trainerImage from "./trainer.jpg";
import arnab from "./Arnab-Das.webp"
import biswajit from "./Biswajit-Ghosh.webp"
import kriti from "./Kriti-Karmakar.webp"
import porinita from "./Porinita-Roy.webp"
import premangshu from "./Premangshu-Chanda.webp"
import pritam from "./Pritam-Das.webp"
import supriyo from "./Supriyo-Chatterjee.webp"

function Trainer() {
    const trainerData = [
        {
            imgLink: arnab,
            alt: "Arnob - Graphic Design Expert",
            name: "Arnob Das",
            position: "Graphic Design Expert"
        },
        {
            imgLink: biswajit,
            alt: "Biswajit - Digital Marketing Expert",
            name: "Biswajit Ghosh",
            position: "Digital Marketing Expert"
        },
        {
            imgLink: kriti,
            alt: "Kriti - UI/UX expert",
            name: "Kriti Karmakar",
            position: "UI/UX Expert"
        },
        {
            imgLink: porinita,
            alt: "Porinita - Digital Marketing Expert",
            name: "Porinita Roy",
            position: "Digital Marketing Expert"
        },
        {
            imgLink: premangshu,
            alt: "Premangshu - web-developer",
            name: "Premangshu Chanda",
            position: "Stock Market specialist/ Web developer"
        },
        {
            imgLink: pritam,
            alt: "Pritam - Graphic-design",
            name: "Pritam Das",
            position: "Graphic Design Expert"
        },
        {
            imgLink: supriyo,
            alt: "Supriyo - AI-marketer",
            name: "Supriyo Chatterjee",
            position: "AI Marketer"
        }
    ];

    return (
        <div className={styles.container}>
            <p className={styles.paragraph}>
                Let's meet with the team of our industry expert trainers. We believe that you will be able to change your life with the most advanced AI integrated training module cooked by Affiliate Ritual.
            </p>
            <Swiper
                effect={'coverflow'}  // Coverflow 3D effect
                grabCursor={true}  // Enables cursor grabbing effect
                centeredSlides={true}  // Keeps the active slide centered
                slidesPerView={'auto'}  // Dynamically adjusts slides based on screen width
                coverflowEffect={{
                    rotate: 20,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                loop={true}  // ✅ Enables infinite swiping
                
                pagination={{ clickable: true }}  // Adds dots navigation
                autoplay={{  // ✅ Auto slides
                    delay: 3000,  // Slides every 3 seconds
                    disableOnInteraction: false, // Prevents pausing when user interacts
                }}
                initialSlide={Math.floor(trainerData.length / 2)}
                speed={600}  // ✅ Makes transitions smooth
                modules={[EffectCoverflow, Pagination]}
                className={styles.sliderContainer}
            >

                {trainerData.map((item, i) => (
                    <SwiperSlide key={i} className={styles.item}>
                        <img
                            className={styles.img}
                            src={item.imgLink}
                            alt={item.alt}
                        />
                        <div className={styles.txtPos}>
                            <div className={styles.name}>{item.name}</div>
                            <div className={styles.position}>{item.position}</div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default Trainer;
