import { db } from "../../firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import { findMonthlyEarningsForUser } from "../findMonthlyEarningsForUser";

// returns array of users, using myARID of someone, as referralId
// take time to think, code is correct
export const findUsersUsingMyARID = async (myARID) => {
    try {
        const usersRef = collection(db, "users");
        const usersQuery = query(usersRef, where("referralId", "==", myARID));
        const querySnapshot = await getDocs(usersQuery);

        const usersUsingMyARID = [];

        for (const doc of querySnapshot.docs) {
            const userRefered = { id: doc.id, ...doc.data() };
            // Find the monthly earnings for each user
            const monthlyEarnings = await findMonthlyEarningsForUser(userRefered.id);
            usersUsingMyARID.push({ ...userRefered, monthlyEarnings });
        }

        return usersUsingMyARID;
    } catch (error) {
        console.error("Error fetching users by referralId:", error);
        return [];
    }
}