import { doc, getDoc, collection, setDoc, updateDoc, increment } from "firebase/firestore";
import { db } from "../../firebase-config.js";
import { getCurrentTimestamp } from "../time/getCurrentTimestamp.js";
import { notification } from 'antd';
import { findUserWhoReferedMe } from "../findUsers/findUserWhoReferedMe.js";

export const updateUserEarnings = async (userId, coursePrice, studentUID) => {
    try {
        if (typeof userId !== 'string') {
            notification.error({
                message: 'Invalid UserId',
                description: `Expected string, received ${typeof userId}`,
                placement: 'topRight',
                duration: 3,
            });
            return;
        }

        console.log("C O U R S E  P R I C E", coursePrice);

        const userRef = doc(db, "users", userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            const userData = userSnap.data();
            let yourAmount;
            if (userData.userTypes === 'team_leader') {
                yourAmount = 0.4 * +coursePrice;
            } else if (userData.userTypes === 'team_member') {
                yourAmount = 0.2 * +coursePrice;
            } else {
                notification.error({
                    message: 'Invalid user type.',
                    description: `Invalid user Data type`,
                    placement: 'topRight',
                    duration: 3,
                });
                return;
            }
            console.log("Y O U R A M O U N T", yourAmount);

            // Update or create totalEarnings and totalCoins in the main user document
            const totalCoins = yourAmount * 0.01;
            await updateDoc(userRef, {
                totalEarnings: increment(yourAmount),
                totalCoins: increment(totalCoins)
            });

            // Add earnings record to subcollection
            const subCollectionEarnings = collection(userRef, "earnings");
            await setDoc(doc(subCollectionEarnings, `orderId-${getCurrentTimestamp()}`), {
                createdAt: getCurrentTimestamp(),
                updatedAt: getCurrentTimestamp(),
                yourAmount: yourAmount,
                sellsType: "active",
                date: new Date(),
                month: new Date().getMonth() + 1, // Adding 1 because getMonth() returns 0-11
                year: new Date().getFullYear(),
            });

            // Add referral record to subcollection
            const subCollectionReferrals = collection(userRef, "referrals");
            await setDoc(doc(subCollectionReferrals, `orderId-${getCurrentTimestamp()}`), {
                createdAt: getCurrentTimestamp(),
                updatedAt: getCurrentTimestamp(),
                referedStudentId: studentUID,
                referType: "active",
                date: new Date(),
                month: new Date().getMonth() + 1, // Adding 1 because getMonth() returns 0-11
                year: new Date().getFullYear(),
            });

            // notification.success({
            //     message: 'Congrats, you have new earnings!',
            //     description: `${userData.userTypes === 'team_leader' ? 'team leader' : 'team member'} earnings updated successfully.`,
            //     placement: 'topRight',
            //     duration: 3,
            // });
            // this will execute only if team_member
            if (userData.userTypes === 'team_member') {
                const teamLeaderId = await findUserWhoReferedMe(userData.referralId);
                updateTeamLeaderPassiveEarnings(teamLeaderId, coursePrice, studentUID);
            }
        } else {
            notification.error({
                message: 'User does not exist.',
                description: `User not found, Contact Developer !`,
                placement: 'topRight',
                duration: 3,
            });
        }
    } catch (error) {
        notification.error({
            message: "Error updating earnings",
            description: `Error : ${error.message}`,
            placement: 'topRight',
            duration: 3,
        });
    }
};

// Update team leader passive earnings
const updateTeamLeaderPassiveEarnings = async (userId, coursePrice, studentUID) => {
    console.log("C O U R S E  P R I C E x2", coursePrice);

    try {
        if (typeof userId !== 'string') {
            notification.error({
                message: 'Invalid UserId;',
                description: `Expected string, received ${typeof userId}`,
                placement: 'topRight',
                duration: 3,
            });
            return;
        }

        const userRef = doc(db, "users", userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            const yourAmount = 0.2 * +coursePrice;

            // Update or create totalEarnings and totalCoins in the main user document
            const totalCoins = yourAmount * 0.01;
            await updateDoc(userRef, {
                totalEarnings: increment(yourAmount),
                totalCoins: increment(totalCoins)
            });

            // Add earnings record to subcollection
            const subCollectionEarnings = collection(userRef, "earnings");
            notification.success({
                message: 'Congrats, New Referall !',
                description: `team leader will get", ${yourAmount}`,
                placement: 'topRight',
                duration: 3,
            });
            await setDoc(doc(subCollectionEarnings, `orderId-${getCurrentTimestamp()}`), {
                createdAt: getCurrentTimestamp(),
                updatedAt: getCurrentTimestamp(),
                yourAmount: yourAmount,
                sellsType: "passive",
                date: new Date(),
                month: new Date().getMonth() + 1, // Adding 1 because getMonth() returns 0-11
                year: new Date().getFullYear(),
            });

            // Add referral record to subcollection
            const subCollectionReferrals = collection(userRef, "referrals");
            await setDoc(doc(subCollectionReferrals, `orderId-${getCurrentTimestamp()}`), {
                createdAt: getCurrentTimestamp(),
                updatedAt: getCurrentTimestamp(),
                referedStudentId: studentUID,
                referType: "passive",
                date: new Date(),
                month: new Date().getMonth() + 1, // Adding 1 because getMonth() returns 0-11
                year: new Date().getFullYear(),
            });
            notification.success({
                message: 'Congrats, New Passive Earnings!',
                description: `Team-leader passive earnings updated successfully`,
                placement: 'topRight',
                duration: 3,
            });
        } else {
            notification.error({
                message: 'User does not exist.',
                description: `Contact Developer`,
                placement: 'topRight',
                duration: 3,
            });
        }
    } catch (error) {
        notification.error({
            message: 'Error updating user earnings',
            description: `${error.message}`,
            placement: 'topRight',
            duration: 3,
        });
    }
};
