import React, { useEffect } from 'react';
import styles from "../../../../../styles/Table.module.css"
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase-config';
import { notification } from 'antd';
import ButtonComponent from '../../../../../components/CssComponents/ButtonComponent/ButtonComponent';

function AttendanceTable({ singleCourseName, singleCourseModule, studentsEnrolledInCourse }) {
    useEffect(() => {
        console.log("singleCourseModule", singleCourseModule);

    }, [])
    const handlePresent = (studentId) => {
        updateAttendance(studentId, 'present');
    };

    const handleAbsent = (studentId) => {
        updateAttendance(studentId, 'absent');
    };

    const updateAttendance = async (studentId, status) => {
        const updatedModule = {
            ...singleCourseModule,
            [status]: singleCourseModule[status] ? [...singleCourseModule[status], studentId] : [studentId],
        };

        // Replace the module in the course object
        const updatedCourse = {
            ...singleCourseName,
            modules: singleCourseName.modules.map(module =>
                new Date(module.date).toISOString() === new Date(singleCourseModule.date).toISOString()
                    ? updatedModule
                    : module
            ),
        };

        const courseRef = doc(db, "courses", updatedCourse.id);
        await updateDoc(courseRef, updatedCourse);

        // Here, you would typically update the database with `updatedCourse`
        console.log("Updated Course:", updatedCourse);
        notification.success({
            message: 'marked successfully',
            description: `Successful`,
            placement: 'topRight',
            duration: 3,
        });
    };

    return (
        <table className={styles.table} cellSpacing={0}>
            <thead className={styles.tablehead}>
                <tr>
                    <th>Student ID</th>
                    <th>Student Name</th>
                    <th>Course Name</th>
                    <th>Course Module</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody className={styles.tablebody}>
                {
                    studentsEnrolledInCourse && studentsEnrolledInCourse.map(student => {

                        const isPresent = singleCourseModule.present && singleCourseModule.present.includes(student.id) ? true : false;
                        const isAbsent = singleCourseModule.absent && singleCourseModule.absent.includes(student.id) ? true : false;

                        return (
                            <tr
                                key={student.id}
                            >
                                <td>{student.myARID.slice(0, 7) + "..." || "NA"}</td>
                                <td>{student.firstName || "NA"}</td>
                                <td>{singleCourseName.courseName || "NA"}</td>
                                <td>{singleCourseModule.name || "NA"}</td>
                                <td className={styles.btns}>
                                    <ButtonComponent
                                        disabled={isPresent || isAbsent}
                                        onClick={() => handlePresent(student.id)}
                                        buttonText={"Present"}
                                        className={styles.btn}
                                    />
                                    <ButtonComponent
                                        disabled={isPresent || isAbsent}
                                        onClick={() => handleAbsent(student.id)}
                                        buttonText={"Absent"}
                                        className={styles.btn}
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    );
}

export default AttendanceTable;
