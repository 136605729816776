import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

// return courses data, total coureses, joined by single users
// mostle for student
export const getCoursesOfUser = async (courseIdsArray) => {
    try {

        if (courseIdsArray.length === 0) {
            console.log("No course IDs found for this student");
            return [];
        }

        const courseDataPromises = courseIdsArray.map(async (courseId) => {
            const courseDocRef = doc(db, "courses", courseId);
            const courseDocSnapshot = await getDoc(courseDocRef);

            if (courseDocSnapshot.exists()) {
                return { courseId: courseId, ...courseDocSnapshot.data() };
            } else {
                console.error(`No course found for ID: ${courseId}`);
                return null;
            }
        });

        // Wait for all course data to be fetched
        const courseDataArray = await Promise.all(courseDataPromises);

        // Filter out any null values (in case a course document was not found)
        return courseDataArray.filter(courseData => courseData !== null);
    } catch (error) {
        console.error("Error fetching course data:", error);
        return [];
    }
}