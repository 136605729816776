import React, { useEffect, useState } from 'react'
import "./Student.css"

import Box from "../../../../../components/box/Box";
import Table from "../../../../../components/Table/Table";

import { getTeamLeadersEarnings } from '../../../../../service/getUsersEarnings/getTeamLeadersEarnings'

import user from "./users.png";
import data from "../Data";
import Arrow from "./arrow.png"
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../../../../firebase-config';
import { fetchPendingTeamLeadersWithCourses, fetchTeamLeadersWithCourses } from '../../../../../service/getPendingStudents';

async function approveCourse(student_uid, courseId) {
  try {
    const userDocRef = doc(db, "users", student_uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Check if courseIdsArray exists
      const courseIdsArray = userData.courseIdsArray || [];

      // Update the status of the specific courseId to "approved"
      const updatedCourses = courseIdsArray.map((course) =>
        course.courseId === courseId
          ? { ...course, status: "approved" }
          : course
      );

      // Update the Firestore document
      await updateDoc(userDocRef, { courseIdsArray: updatedCourses });
      console.log(`Course ${courseId} has been approved.`);
    } else {
      console.log("No such user found!");
    }
  } catch (error) {
    console.error("Error approving the course:", error);
  }
}

async function rejectCourse(student_uid, courseId) {
  try {
    const userDocRef = doc(db, "users", student_uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Check if courseIdsArray exists
      const courseIdsArray = userData.courseIdsArray || [];

      // Update the status of the specific courseId to "rejected"
      const updatedCourses = courseIdsArray.map((course) =>
        course.courseId === courseId
          ? { ...course, status: "rejected" }
          : course
      );

      // Update the Firestore document
      await updateDoc(userDocRef, { courseIdsArray: updatedCourses });
      console.log(`Course ${courseId} has been rejected.`);
    } else {
      console.log("No such user found!");
    }
  } catch (error) {
    console.error("Error rejecting the course:", error);
  }
}

const Student = () => {
  const [teamLeadersEarnings, setTeamLeadersEarnings] = useState([]);
  const [displayCount, setDisplayCount] = useState(5);
  const [pendingStudents, setPendingStudents] = useState([])
  const [allStudents, setAllStudents] = useState([])
  function refreshPage() {
    window.location.reload();
  }

  useEffect(() => {
    const fetchEarnings = async () => {
      const earnings = await getTeamLeadersEarnings();
      setTeamLeadersEarnings(earnings);
      const pending = await fetchPendingTeamLeadersWithCourses();
      const all = await fetchTeamLeadersWithCourses()
      setAllStudents(all)
      setPendingStudents(pending)
      console.log("PENDING", pending);
      // console.log("ALL", allStudents);


    };

    fetchEarnings();
  }, []);

  const handleApprove = async (id, courseId) => {
    try {
      await approveCourse(id, courseId); // Call the function to approve the course
      setPendingStudents((prevStudents) =>
        prevStudents.filter(
          (student) =>
            !(student.id === id && student.courseInfo.courseId === courseId)
        )
      );
      console.log(`Course ${courseId} for student ${id} approved and removed from pending.`);
    } catch (error) {
      console.error(`Error approving course ${courseId} for student ${id}:`, error);
    }
  };

  const handleReject = async (id, courseId) => {
    try {
      await rejectCourse(id, courseId); // Call the function to reject the course
      setPendingStudents((prevStudents) =>
        prevStudents.filter(
          (student) =>
            !(student.id === id && student.courseInfo.courseId === courseId)
        )
      );
      console.log(`Course ${courseId} for student ${id} rejected and removed from pending.`);
    } catch (error) {
      console.error(`Error rejecting course ${courseId} for student ${id}:`, error);
    }
  };


  return (
    <div className='students'>

      <div className="fl">
        <h1 className="heading">Students</h1>
        <button className="back-button" onClick={refreshPage}>
          <img src={Arrow} alt="" />
        </button>
      </div>

      <h3 className="subheading">Dashboard/ Students</h3>

      {/* <div className="boxes">
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
        <Box subhed={"User"} value={"40,689"} logo={user} />
      </div> */}

      <div className="tables">



        <h1 className="heading2"> Active Students</h1>
        <div className="tb">
          <div className="tablecon">
            <table className="table" cellSpacing={0} style={{ textAlign: 'center' }}>
              <thead className="tablehead">
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Photo</th>
                  <th>Course Name</th>
                  <th>Last Installment date and amount</th>
                  <th>Last payment date and amount</th>
                </tr>
              </thead>
              <tbody className="tablebody">
                {allStudents.slice(0, displayCount).map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.firstName} {item.lastName}</td>
                    <td>{item.registrationImage?<img src={item.registrationImage} style={{ width: "100px", height: "auto", objectFit: "contain" }} />:<>No proof attached</>}</td>
                    <td>{item.courseInfo.courseName}</td>
                    <td>{item.courseInfo.latestInstallment.dueDate} || {item.courseInfo.latestInstallment.amount}</td>
                    <td>
                      {item.courseInfo.userLastPayment
                        ? `${item.courseInfo.userLastPayment.datePaid} || ${item.courseInfo.latestInstallment.amount}`
                        : "Not Paid till now"}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <h1 className="heading2">In-Active Students</h1>
        <div className="tb">
          <div className="tablecon">
            <table className="table" cellSpacing={0} style={{ textAlign: 'center' }}>
              <thead className="tablehead">
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Photo</th>
                  <th>Course Name</th>
                  <th>Registration Fees</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="tablebody">
                {pendingStudents.slice(0, displayCount).map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.firstName} {item.lastName}</td>
                    <td><img src={item.registrationImage} style={{ width: "100px", height: "auto", objectFit: "contain" }} /></td>
                    <td>{item.courseInfo.courseName}</td>
                    <td>{item.courseInfo.registrationFees}</td>

                    <td>
                      <a className="btn" style={{ cursor: "pointer" }} onClick={() => { handleApprove(item.id, item.courseInfo.courseId) }}>Approve</a>
                      <a className="btn" style={{ cursor: "pointer" }} onClick={() => { handleReject(item.id, item.courseInfo.courseId) }}>Reject</a>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* pending students  */}
        <h1 className="heading2">Pending students</h1>
        <div className="tb">
          <div className="tablecon">
            <table className="table" cellSpacing={0} style={{ textAlign: 'center' }}>
              <thead className="tablehead">
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Photo</th>
                  <th>Course Name</th>
                  <th>Registration Fees</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="tablebody">
                {pendingStudents.slice(0, displayCount).map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.firstName} {item.lastName}</td>
                    <td><img src={item.registrationImage} style={{ width: "100px", height: "auto", objectFit: "contain" }} /></td>
                    <td>{item.courseInfo.courseName}</td>
                    <td>{item.courseInfo.registrationFees}</td>

                    <td>
                      <a className="btn" style={{ cursor: "pointer" }} onClick={() => { handleApprove(item.id, item.courseInfo.courseId) }}>Approve</a>
                      <a className="btn" style={{ cursor: "pointer" }} onClick={() => { handleReject(item.id, item.courseInfo.courseId) }}>Reject</a>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Student