import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import Signin from './Signin/Signin';
import Dashboard from './Dashboard/Dashboard';
import { useAuth } from '../../provider/Auth.provider';
import { findUserByUID } from '../../service/findUsers/findUserByUID';
import { useLocation, useNavigate } from 'react-router-dom';


const ClubAdmin = () => {
  const { user, loading } = useAuth();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (!user) return; // Wait for user to load, before checking user access
    const checkUserAccess = async () => {
      try {
        if (!user?.uid) {
          notification.error({
            message: 'Credentials not found',
            description: 'Login to access dashboard',
            placement: 'topRight',
            duration: 3,
          });
          setIsSignedIn(false);
          return;
        }
        const userDetails = await findUserByUID(user.uid);
        if (userDetails?.userTypes === 'club_admin') {
          setIsSignedIn(true);
          if (location.pathname !== "/clubadmin/dashboard") {
            navigate('/clubadmin/dashboard', { replace: true });
          }
          return;
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'An error occurred while checking user access',
          placement: 'topRight',
          duration: 3,
        });
        setIsSignedIn(false);
      }
    };

    checkUserAccess();
  }, [user, navigate, location]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {!isSignedIn ? <Signin /> : <Dashboard />}
    </>
  );
};

export default ClubAdmin;

