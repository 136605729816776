import React, { useEffect, useState } from 'react'
import Box from '../../../../components/box/Box'
import Bargraph from '../../../../components/Graph/Bargraph'
import Graph from '../../../../components/Graph/Graph'
import userLogo from "../assets/users.png"
import Table from "../../../../components/Table/Table";
import Paragraph from 'antd/es/typography/Paragraph';
import { getMyARIDFromUid } from "../../../../service/getMyARIDFromUid.js"
import { findUsersUsingMyARID } from '../../../../service/findUsers/findUsersUsingMyARID.js';
import { useAuth } from "../../../../provider/Auth.provider.jsx"
import styles from "./Dash.module.css"


const Dash = () => {
  const { user } = useAuth();
  const [arID, setArID] = useState('')
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchARID = async () => {

      if (!user.uid) {
        return;
      }

      if (user?.uid) {
        const arID = await getMyARIDFromUid(user.uid);
        setArID(arID);
      }
    };
    fetchARID();
  }, [user?.uid]);

  useEffect(() => {
    const fetchData = async () => {
      if (arID) {
        const usersUsingMyARID = await findUsersUsingMyARID(arID);
        setData(usersUsingMyARID);
      }
    }
    fetchData();
  }, [arID])

  return (
    <div className={styles.container}>

      <div className={styles.progressWrapper}>
        <div className={styles.heading}>Your Referal Id :
          <Paragraph className={styles.arIdText} copyable> {arID} </Paragraph>
        </div>
      </div>

      <div className={styles.progressWrapper}>
        <div className={styles.heading}>Progress</div>
        <div className={styles.boxes}>
          <Box subhed={"User"} value={"40,689"} logo={userLogo} />
          <Box subhed={"User"} value={"40,689"} logo={userLogo} />
          <Box subhed={"User"} value={"40,689"} logo={userLogo} />
          <Box subhed={"User"} value={"40,689"} logo={userLogo} />
        </div>
      </div>


      <div className={styles.progressWrapper}>
        <div className={styles.heading}>Progress</div>
        <div className={styles.graphes}>
          <Graph />
          <Bargraph />
        </div>
      </div>

      <div className={styles.progressWrapper}>
        <div className={styles.heading}>Active Students</div>
        <Table data={data} showAction={false} />
      </div>

      <div className={styles.progressWrapper}>
        <div className={styles.heading}>In Active Students</div>
        <Table data={data} showAction={false} />
      </div>

      <div className={styles.progressWrapper}>
        <div className={styles.heading}>Passed Out Students</div>
        <Table data={data} showAction={false} />
      </div>
    </div>
  )
}

export default Dash