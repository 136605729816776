// Disclaimer.js
import React, { useEffect, useState } from "react";
import styles from "./Disclaimer.module.css";
import Header from "../landing/header/Header";
import Footer from "../landing/Footer/Footer";
import HeaderLoginOptions from "../components/LandingComponents/HeaderLoginOptions/HeaderLoginOptions";
import HeaderRegisterOptions from "../components/LandingComponents/HeaderRegisterOptions/HeaderRegisterOptions";

const Disclaimer = () => {
  useEffect(() => {
    const originalStyles = {
      fontFamily: document.body.style.fontFamily,
      margin: document.body.style.margin,
      padding: document.body.style.padding,
      boxSizing: document.body.style.boxSizing,
      outline: document.body.style.outline,
      border: document.body.style.border,
      textDecoration: document.body.style.textDecoration,
      fontSize: document.documentElement.style.fontSize,
      overflowX: document.documentElement.style.overflowX,
      scrollBehavior: document.documentElement.style.scrollBehavior,
      scrollPaddingTop: document.documentElement.style.scrollPaddingTop,
    };

    // Apply global styles
    document.body.style.fontFamily = '"DM Sans", sans-serif';
    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.boxSizing = "border-box";
    document.body.style.outline = "none";
    document.body.style.border = "none";
    document.body.style.textDecoration = "none";
    document.documentElement.style.fontSize = "62.5%";
    document.documentElement.style.overflowX = "hidden";
    document.documentElement.style.scrollBehavior = "smooth";
    document.documentElement.style.scrollPaddingTop = "7rem";

    return () => {
      // Cleanup global styles
      document.body.style.fontFamily = originalStyles.fontFamily;
      document.body.style.margin = originalStyles.margin;
      document.body.style.padding = originalStyles.padding;
      document.body.style.boxSizing = originalStyles.boxSizing;
      document.body.style.outline = originalStyles.outline;
      document.body.style.border = originalStyles.border;
      document.body.style.textDecoration = originalStyles.textDecoration;
      document.documentElement.style.fontSize = originalStyles.fontSize;
      document.documentElement.style.overflowX = originalStyles.overflowX;
      document.documentElement.style.scrollBehavior = originalStyles.scrollBehavior;
      document.documentElement.style.scrollPaddingTop = originalStyles.scrollPaddingTop;
    };
  }, []);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [activeHash, setActiveHash] = useState(window.location.hash || "#home");
  const [isHovered, setIsHovered] = useState(false);

  const openRegister = () => {
      setIsRegisterOpen(true);
  };

  const closeRegister = () => {
      setIsRegisterOpen(false);
  };

  const openLogin = () => {
      setIsHovered(false)
      setIsLoginOpen(true);
  };

  const closeLogin = () => {
      setIsLoginOpen(false);
  };

  // Update `activeHash` when URL fragment changes
  useEffect(() => {
      const handleHashChange = () => {
          setActiveHash(window.location.hash || "#home");
      };

      // Listen for hash changes
      window.addEventListener("hashchange", handleHashChange);

      // Cleanup event listener
      return () => {
          window.removeEventListener("hashchange", handleHashChange);
      };
  }, []);

  return (
    <>
      <HeaderLoginOptions isLoginOpen={isLoginOpen} closeLogin={closeLogin} />
            <HeaderRegisterOptions isRegisterOpen={isRegisterOpen} closeRegister={closeRegister} />

            <Header
                activeHash={activeHash}
                openLogin={openLogin}
                openRegister={openRegister}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
            />
      <div className={styles.disclaimerContainer}>
        <h1 className={styles.heading}>Disclaimer</h1>
        <div className={styles.content}>
          <p>
            The information provided on this website is for general informational
            purposes only. All information on the Site is provided in good faith;
            however, we make no representation or warranty of any kind, express
            or implied, regarding the accuracy, adequacy, validity, reliability,
            availability, or completeness of any information on the Site.
          </p>
          <p>
            Under no circumstance shall we have any liability to you for any loss
            or damage of any kind incurred as a result of the use of the site or
            reliance on any information provided on the site. Your use of the
            site and your reliance on any information on the site is solely at
            your own risk.
          </p>
          <p>
            This website may contain links to other websites or content belonging
            to or originating from third parties or links to websites and
            features. Such external links are not investigated, monitored, or
            checked for accuracy, adequacy, validity, reliability, availability,
            or completeness by us.
          </p>
          <p>
            We do not warrant, endorse, guarantee, or assume responsibility for
            the accuracy or reliability of any information offered by third-party
            websites linked through the site or any website or feature linked in
            any banner or other advertising. We will not be a party to or in any
            way be responsible for monitoring any transaction between you and
            third-party providers of products or services.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimer;
