import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";

export const fetchPendingTeamLeadersWithCourses = async () => {
    try {
        // Query Firestore for team_leader users with status 'pending'
        const q = query(
            collection(db, "users"),
            where("userTypes", "==", "team_leader")
        );

        const querySnapshot = await getDocs(q);

        const results = [];

        for (const userDoc of querySnapshot.docs) {
            const userData = userDoc.data();

            // Ensure the user has a 'registrationImage' and 'courseIdsArray'
            if ("registrationImage" in userData && Array.isArray(userData.courseIdsArray)) {
                for (const course of userData.courseIdsArray) {
                    const courseId = course.courseId || course; // Handle cases where course is a string

                    // Fetch course details from the 'course' collection
                    const courseDocRef = doc(db, "courses", courseId);
                    const courseDoc = await getDoc(courseDocRef);

                    let courseDetails = {
                        courseName: "Unknown", // Default values in case course details are missing
                        registrationFees: "Unknown",
                    };

                    if (courseDoc.exists()) {
                        console.log("TRUEEE");

                        const courseData = courseDoc.data();
                        if (course.status === "pending") {

                            courseDetails = {
                                courseName: courseData.courseName || "Unknown",
                                registrationFees: courseData.registrationFees || "Unknown",
                            };

                            results.push({
                                id: userDoc.id, // Student ID
                                firstName: userData.firstName,
                                lastName: userData.lastName,
                                email: userData.email,
                                phone: userData.phone,
                                registrationImage: userData.registrationImage,
                                courseInfo: {
                                    courseId: courseId,
                                    status: course.status || "unknown",
                                    proof: course.proof || userData.registrationImage,
                                    ...courseDetails, // Add course name and registration fees
                                },
                            });
                        }
                    } else {
                        console.error(`Course document with ID ${courseId} does not exist.`);
                    }

                    // Push a record for each course with student and course details

                }
            }
        }

        console.log("Filtered Team Leaders with Courses and Details:", results);
        return results;
    } catch (error) {
        console.error("Error fetching team leaders with courses:", error);
        return [];
    }
};



/**
 * Fetch all team leaders and their enrolled courses.
 * Each course appears as a separate entry under the student.
 */
export const fetchTeamLeadersWithCourses = async () => {
    try {
        const q = query(collection(db, "users"), where("userTypes", "==", "team_leader"));
        const querySnapshot = await getDocs(q);

        const results = [];

        for (const userDoc of querySnapshot.docs) {
            const userData = userDoc.data();

            if ("registrationImage" in userData && Array.isArray(userData.courseIdsArray)) {
                for (const course of userData.courseIdsArray) {
                    const courseId = course.courseId || course;

                    // Fetch course details
                    const courseDocRef = doc(db, "courses", courseId);
                    const courseDoc = await getDoc(courseDocRef);

                    // ✅ Skip courses that don't exist
                    if (!courseDoc.exists()) {
                        console.warn(`Skipping missing course: ${courseId}`);
                        continue; // Move to the next course
                    }

                    const courseData = courseDoc.data();
                    let latestInstallment = null;
                    let userLastPayment = null;

                    // ✅ Identify the latest installment by date
                    if (Array.isArray(courseData.installments) && courseData.installments.length > 0) {
                        // Sort installments by date
                        const sortedInstallments = courseData.installments.sort((a, b) => new Date(a.date) - new Date(b.date));
                        latestInstallment = sortedInstallments[sortedInstallments.length - 1];

                        // Find if the user has paid any installment
                        for (const installment of sortedInstallments) {
                            const userPayment = installment.paid?.find(payment => payment.userId === userDoc.id);
                            if (userPayment) {
                                userLastPayment = {
                                    datePaid: `${userPayment.day}-${userPayment.month}-${userPayment.year}`,
                                    amount: installment.price,
                                    proof:userPayment.proof||""
                                };
                            }
                        }
                    }

                    // ✅ Store course and installment details
                    results.push({
                        userId: userDoc.id,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        email: userData.email,
                        phone: userData.phone,
                        registrationImage: userLastPayment?.proof ||null,
                        courseInfo: {
                            courseId: courseId,
                            status: course.status || "unknown",
                            proof: course.proof || userData.registrationImage,
                            courseName: courseData.courseName || "Unknown",
                            registrationFees: courseData.registrationFees || "Unknown",
                            latestInstallment: latestInstallment ? {
                                dueDate: latestInstallment.date,
                                amount: latestInstallment.price,
                                isPaid: latestInstallment.paid?.some(payment => payment.userId === userDoc.id) || false,
                            } : null,
                            userLastPayment: userLastPayment || null,
                        },
                    });
                }
            }
        }

        console.log("Team Leaders with Courses and Installment Details:", results);
        return results;
    } catch (error) {
        console.error("Error fetching team leaders with courses:", error);
        return [];
    }
};



