import React, { useEffect, useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase-config.js";
import { getCurrentTimestamp } from "../../../service/time/getCurrentTimestamp.js";
import { notification } from "antd";
import styles from "../../../styles/Register.module.css"
import RegisterLogo from "../../../components/CssComponents/RegisterLogo/RegisterLogo.jsx";
import ArLogo from "../../../components/CssComponents/ArLogo/ArLogo.jsx";
import PhoneInputComponent from "../../../components/CssComponents/PhoneInput/PhoneInputComponent.jsx";
import ButtonComponent from "../../../components/CssComponents/ButtonComponent/ButtonComponent.jsx";
import OtpInputContainer from "../../../components/FlowComponents/OtpInputContainer/OtpInputContainer.jsx";
import { Link } from "react-router-dom";

const TrainerRegister = () => {
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [uid, setUid] = useState('');

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    adharNumber: "",
    panNumber: "",
    address: "",
    accountNumber: "",
    accountType: "",
    ifscCode: "",
    createdAt: "",
    updatedAt: "",
    selectExperience: "",
    userTypes: "club_trainer",
  });

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        console.log('reCAPTCHA solved');
      }
    });

    // Cleanup on component unmount
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);

  const handleOtpChange = (otpComing) => {
    setOtp(otpComing);
  };

  const sendVerificationCode = (e) => {
    e.preventDefault();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, formData.phone, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        notification.info({
          message: 'OTP sent to your phone',
          description: 'Otp sent to your phone',
          placement: 'topRight',
          duration: 3, // Display for 3 seconds
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Error sending OTP',
          description: `${error.message}`,
          placement: 'topRight',
          duration: 3,
        });
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (!confirmationResult) {

      notification.info({
        message: 'OTP Request Needed',
        description: 'First request the OTP',
        placement: 'topRight',
        duration: 3, // Display for 3 seconds
      });
      return;
    }
    confirmationResult.confirm(otp)
      .then(async (result) => {
        const user = result.user;
        setUid(user.uid);
        notification.success({
          message: 'Phone number verified',
          description: `User verified`,
          placement: 'topRight',
          duration: 3,
        });
        // Check if the user data already exists
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          // If user data exists, use it and prevent overwriting myARID
          // fetching the data if exists & updating formData
          const existingData = userDocSnapshot.data();
          setFormData(existingData);
        } else {
          // If user data does not exist,
          // getCurrentTimesTamp
          const createdAt = getCurrentTimestamp();
          setFormData(prevData => ({ ...prevData, createdAt }));
        }
      })
      .catch((error) => {
        console.error('Error verifying OTP:', error);
        notification.error({
          message: 'Error verifying OTP',
          description: `${error.message}`,
          placement: 'topRight',
          duration: 3,
        })
      });
  };


  const addUserToFirestore = async () => {
    try {
      await setDoc(doc(db, "users", uid), { // Updated to setDoc
        uid: uid,
        ...formData
      });
      notification.success({
        message: 'Registration Successfull',
        description: `User Data Created`,
        placement: 'topRight',
        duration: 3,
      });
    } catch (error) {
      notification.error({
        message: 'Error adding user',
        description: `${error.message}`,
        placement: 'topRight',
        duration: 3,
      });
    }
  };


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updatePhoneNumber = (phoneNumber) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: phoneNumber,
    }));
  };





  // implement the updation part, what if trainer is already registered
  // this , you can do at the time of uid or otp confirmation
  const handleRegister = (e) => {
    e.preventDefault();

    const updatedAt = getCurrentTimestamp();
    setFormData({ ...formData, updatedAt })

    if (uid) {
      addUserToFirestore();
    } else {
      notification.info({
        message: 'Please verify OTP First',
        description: `verfiy OTP first`,
        placement: 'topRight',
        duration: 3,
      });
    }
  };

  return (
    <>
      <div className={styles.RegisterBackground}>
        <div className={styles.RegisterContainer}>

          <div className={styles.RegisterImage}>
            <RegisterLogo />
          </div>

          <div className={styles.formSection}>
            <ArLogo />
            <div className={styles.headingText}>Trainer Registration</div>
            <form className={styles.formContainer}>

              <div id="recaptcha-container"></div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Phone Number <sup>*</sup></div>
                <PhoneInputComponent
                  value={formData.phone}
                  onChange={updatePhoneNumber}
                />
              </div>


              <ButtonComponent
                onClick={sendVerificationCode}
                className={""}
                buttonText={"Send OTP"}
              />

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Enter Otp <sup>*</sup></div>
                <OtpInputContainer onOtpChange={handleOtpChange} />
              </div>

              <ButtonComponent
                onClick={verifyOtp}
                className={""}
                buttonText={"Verify OTP"}
              />

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>First Name <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  required
                />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Last Name <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Enter last name"
                  required
                />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Email <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="anurag@gklite.in"
                  required
                />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Date of Birth <sup>*</sup></div>
                <input
                  type="date"
                  className={styles.input}
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  required
                />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Aadhar Number <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="aadharNumber"
                  value={formData.aadharNumber}
                  onChange={handleChange}
                  placeholder="Aadhar Number"
                  required />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Pan Number <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="panNumber"
                  value={formData.panNumber}
                  onChange={handleChange}
                  placeholder="Pan Number" required />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Address <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Address" required
                />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Select an Experience Level <sup>*</sup></div>
                <select
                  className={styles.selectInput}
                  name="selectExperience"
                  value={formData.selectExperience}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select</option>
                  <option value="fresher">  Fresher  </option>
                  <option value="experienced">  Experienced </option>
                </select>

              </div>



              <div className={styles.headingText}>Bank Details</div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Account Number <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  placeholder="Account Number"
                  required
                />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Account Type <sup>*</sup></div>
                <input type="text" className={styles.input} name="accountType" value={formData.accountType} onChange={handleChange} placeholder="Account Type" required />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Ifsc Code <sup>*</sup></div>
                <input type="text" className={styles.input} name="ifscCode" value={formData.ifscCode} onChange={handleChange} placeholder="Ifsc Code" required />
              </div>





              <ButtonComponent
                onClick={handleRegister}
                className={""}
                buttonText={"Register"}
              />


              <Link
                to="/trainer?action=login"
                style={{
                  textDecoration: 'none',
                }}
              >
                <div className={styles.alr}>already a member? <span>Login</span></div>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainerRegister;



