import { where, getDocs, collection, query } from "firebase/firestore";
import { db } from "../../firebase-config";

export const getCoursesByTrainer = async (trainer_uid) => {

    if (!trainer_uid) {
        console.error("Trainer UID is not available.");
        return [];
    }

    try {
        const coursesRef = collection(db, "courses");
        const q = query(coursesRef, where("selectedTrainer", "==", trainer_uid));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log("No courses found for this trainer.");
            return [];
        }

        const courses = [];
        querySnapshot.forEach((doc) => {
            courses.push({ id: doc.id, ...doc.data() });
        });

        // returning array
        return courses;
    } catch (error) {
        console.error("Error fetching courses: ", error);
        return [];
    }
};