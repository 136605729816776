import React from 'react';
import styles from '../../../styles/DashboardStyles/Header.module.css';
import coin from "./assets/coin.png"
import menu from "./assets/menu.png"
import notif from "./assets/notif.png"
import profile from './assets/profile.png';
import reviewActive from "./assets/reviewsActive.png"

const Header = ({ userData, toggleMenu, openNotifyModal, openProfileModal }) => {
    console.log(userData);
    console.log("header")
    return (
        <div className={styles.header}>
            <div className={styles.left}>
                <div className={styles.menugrid} onClick={toggleMenu}>
                    <img src={menu} alt="Menu" />
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.reviews}>
                    <img src={reviewActive} height={25} alt="review" /> {userData?.averageReview || 0}
                </div>
                <div className={styles.coins}>
                    <img src={coin} height={25} alt="Coin" /> {userData?.totalCoins || 0}
                </div>
                {/* <div
                    onClick={openNotifyModal}
                    className={styles.notifications}>
                    <img src={notif} height={30} alt="Notifications" />
                </div> */}
                <div
                    onClick={openProfileModal}
                    className={styles.profile}>
                    <img src={userData.photoUrl || profile} alt="Profile" />
                </div>
            </div>
        </div>
    );
};

export default Header;
