import React, { useEffect, useState } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import styles from "../../../styles/Signin.module.css"
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase-config';
import { notification } from 'antd';
import LoginInput from '../../../components/FlowComponents/LoginInput/LoginInput';
import LoginOtpVerify from '../../../components/FlowComponents/LoginOtpVerify/LoginOtpVerify';
import ArLogo from '../../../components/CssComponents/ArLogo/ArLogo';
import { findUserByUID } from '../../../service/findUsers/findUserByUID';

const Signin = () => {
  const navigate = useNavigate();
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [showSign, setShowSign] = useState(true);

  useEffect(() => {
    const recaptchaContainer = document.getElementById('recaptcha-container');
    if (recaptchaContainer) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          console.log('reCAPTCHA solved');
        }
      });
    }

    // Cleanup on component unmount
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);

  const handleSignInClick = (phone) => {
    sendVerificationCode(phone);
    setShowSign(false);

  };

  const handleOtpVerify = (otpComing) => {
    verifyOtp(otpComing);
  };

  const sendVerificationCode = (phone) => {
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        notification.success({
          message: 'OTP sent to your phone',
          description: 'OTP sent to your phone',
          placement: 'topRight',
          duration: 3,
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to send OTP',
          description: 'Please try again.',
          placement: 'topRight',
          duration: 3,
        });
      });
  };

  const verifyOtp = (otp) => {
    if (!confirmationResult) {
      notification.info({
        message: 'OTP Request Needed',
        description: 'First request the OTP',
        placement: 'topRight',
        duration: 3,
      }); return;
    }
    confirmationResult.confirm(otp)
      .then(async (result) => {
        const user = result.user;
        const userDetails = await findUserByUID(user.uid);
        if (!userDetails || userDetails.userTypes !== 'team_leader') {
          notification.info({
            message: 'User Details not found',
            description: `Redirecting to register in 5 seconds`,
            placement: 'topRight',
            duration: 5,
          });
          setTimeout(() => {
            navigate('/teamleader?action=register', { replace: true });
          }, 5000);
        }
        notification.success({
          message: 'Phone number & user verified',
          description: `Phone number : ${user.phoneNumber}, UserType : ${userDetails.userTypes}`,
          placement: 'topRight',
          duration: 3,
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Verification Failed',
          description: `${error.message} Failed to verify OTP. Please try again.`,
          placement: 'topRight',
          duration: 3,
        });
      });
  };

  return (
    <>
      <div className={styles.signin}>
        <div className={styles.container}>
          <div id="recaptcha-container"></div>

          <ArLogo />
          <div className={styles.heading}>Club Leader Login</div>
          {
            showSign ?
              <LoginInput
                onSignInClick={handleSignInClick}
              /> :
              <LoginOtpVerify
                onOtpVerify={handleOtpVerify}
              />
          }
          <Link
            to="/teamleader?action=register"
            style={{
              textDecoration: 'none',
            }}
          >
            <p className={styles.naa}>Need an account? <span>Register</span></p>
          </Link>

        </div>
      </div>
    </>
  );
};

export default Signin;
