import React, { useEffect, useRef } from 'react';
import "./Bar.css";
import Chart from 'chart.js/auto';

const Bargraph = ({ title = "Weekly Revenue", labels = [], data = [], color = "#e4c748" }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current || labels.length === 0 || data.length === 0) return;

    const ctx = chartRef.current;

    // Destroy existing chart before creating a new one
    if (ctx.chart) {
      ctx.chart.destroy();
    }

    ctx.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: title,
          data: data,
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
          borderRadius: 20,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: { display: false } // Hide Y-axis
        },
        plugins: {
          legend: { display: false } // Hide legend
        },
        indexAxis: 'x', // Keep bars horizontal
        barPercentage: 0.3,
        categoryPercentage: 1,
      }
    });

  }, [labels, data, title, color]); // Re-run when props change

  return (
    <div className="bargraph">
      <div className="barhed">
        <h2 className="lefty">{title}</h2>
      </div>
      <div className="bargrph" style={{ width: '100%', height: "200px" }}>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
}

export default Bargraph;
