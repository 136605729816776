import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styles from './System.module.css'; // Import the CSS Module

function System() {
    const systemData = [
        { videoId: "PpkcXIPMFME", name: "Describing about our system", timestamp: 1 },  // ✅ No.1 Video
        { videoId: "n5DqR_mAP_8", name: "Describing about our system", timestamp: 4704 },  // ✅ No.2 Video (Starts at 4704s)
    ];

    return (
        <div className={styles.container}>
            <p className={styles.paragraph}>
                Affiliate Ritual is introducing India's first-ever Super Affiliate Passive business model. Where anyone can join and start earning from their home and it’s totally free! Why are we calling it Super Affiliate or Passive business model? Want to know? Take a look at the videos.
            </p>

            <div className={styles.items}>
                {systemData.map((item, i) => (
                    <motion.div
                        key={i}
                        className={styles.itemWrapper}
                        whileInView={{ opacity: 1, scale: 1 }}
                        initial={{ opacity: 0, scale: 0.9 }}
                        transition={{ duration: 0.5, ease: "easeOut" }}
                        viewport={{ once: true, amount: 0.5 }} // ✅ Only animates when 50% in viewport
                    >
                        <LazyYouTube videoId={item.videoId} title={item.name} timestamp={item.timestamp} />
                    </motion.div>
                ))}
            </div>
        </div>
    );
}

export default System;

// ✅ Lazy Loading YouTube Iframes for Performance Optimization & Timestamp Support
const LazyYouTube = ({ videoId, title, timestamp }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <div className={styles.videoContainer} onClick={() => setIsPlaying(true)}>
            {!isPlaying ? (
                <div className={styles.thumbnailWrapper}>
                    <img
                        className={styles.thumbnail}
                        src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`} // ✅ Uses YouTube Thumbnail
                        alt={title}
                        loading="lazy"
                    />
                    <div className={styles.playButton}>▶</div> {/* ✅ Play button overlay */}
                </div>
            ) : (
                <iframe
                    className={styles.video}
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1&controls=1&modestbranding=1&rel=0&start=${timestamp}`} // ✅ Timestamp added
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            )}
        </div>
    );
};
