import React, { useEffect, useState } from "react";

import Box from "../../../../components/box/Box";
import Bargraph from "../../../../components/Graph/Bargraph";
import PieChart from "../../../../components/Pie/PieChart";
import Table from "../../../../components/Table/Table";

// Different table for trainer 
import TrainerTable from "../../../../components/Trainer/TrainerTable";

import { getTeamLeadersEarnings } from "../../../../service/getUsersEarnings/getTeamLeadersEarnings"
import { getTeamMembersEarnings } from "../../../../service/getUsersEarnings/getTeamMembersEarnings"
import { getClubTrainersEarnings } from "../../../../service/getUsersEarnings/getClubTrainersEarnings.js"

import Team from "./Team/Team";
import Student from "./Student/Student";
import Trainer from "./Trainer/Trainer";

import userIcon from "./users.png"; // User icon

import "./Admindash.css";
import { getMonthlyRevenue } from "../../../../service/getMonthlyRevenue.js";

const AdminDa = () => {
  const [teamLeadersEarnings, setTeamLeadersEarnings] = useState([]);
  const [teamMembersEarnings, setTeamMembersEarnings] = useState([]);
  const [clubTrainersEarnings, setClubTrainersEarnings] = useState([]);

  const [totalUsers, setTotalUsers] = useState(0);
  const [totalLeaders, setTotalLeaders] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalTrainers, setTotalTrainers] = useState(0);
  const [revenueData, setRevenueData] = useState({ labels: [], data: [] });

  const [showTeam, setShowTeam] = useState(false);
  const [showStudent, setShowStudent] = useState(false);
  const [showTrainer, setShowTrainer] = useState(false);

  useEffect(() => {
    const fetchEarnings = async () => {
      try {
        const [leaders, members, trainers, revenue] = await Promise.all([
          getTeamLeadersEarnings(),
          getTeamMembersEarnings(),
          getClubTrainersEarnings(),
          getMonthlyRevenue(),

        ]);

        setTeamLeadersEarnings(leaders);
        setTeamMembersEarnings(members);
        setClubTrainersEarnings(trainers);
        setRevenueData(revenue);


        // Calculate totals dynamically
        setTotalUsers(leaders.length + members.length + trainers.length);
        setTotalLeaders(leaders.length);
        setTotalMembers(members.length);
        setTotalTrainers(trainers.length);
      } catch (error) {
        console.error("Error fetching earnings data:", error);
      }
    };

    fetchEarnings();
  }, []);

  const ShowTeam = () => {
    setShowTeam(true);
    setShowStudent(false);
    setShowTrainer(false);
  };

  const ShowStudent = () => {
    setShowTeam(false);
    setShowStudent(true);
    setShowTrainer(false);
  };

  const ShowTrainer = () => {
    setShowTeam(false);
    setShowStudent(false);
    setShowTrainer(true);
  };

  if (showTeam) {
    return <Team />;
  } else if (showStudent) {
    return <Student />;
  } else if (showTrainer) {
    return <Trainer />;
  }

  return (
    <div className="admindash">
      <h1 className="heading">Dashboard</h1>

      {/* Dynamic Stats Section */}
      <div className="boxes">
        <Box subhed={"Total Users"} value={totalUsers.toLocaleString()} logo={userIcon} />
        <Box subhed={"Club Leaders"} value={totalLeaders.toLocaleString()} logo={userIcon} />
        <Box subhed={"Club Members"} value={totalMembers.toLocaleString()} logo={userIcon} />
        <Box subhed={"Trainers"} value={totalTrainers.toLocaleString()} logo={userIcon} />
      </div>

      <div className="graphs">
        <PieChart data={[totalMembers, totalLeaders, totalTrainers]} labels={["Members", "Leaders", "Trainers"]} />
        <Bargraph
          title="Monthly Revenue"
          labels={revenueData.labels}
          data={revenueData.data}
          color="#e4c748"
        />
      </div>

      <div className="tables">
        <div className="hes">
          <h1 className="heading">Club Members</h1>
          <h2 className="subha" onClick={ShowTeam}>View All</h2>
        </div>
        <Table data={teamMembersEarnings} onViewFull={() => { }} showAction={false} />

        <div className="hes">
          <h1 className="heading">Club Leaders</h1>
          <h2 className="subha" onClick={ShowStudent}>View All</h2>
        </div>
        <Table data={teamLeadersEarnings} onViewFull={() => { }} showAction={false} />

        <div className="hes">
          <h1 className="heading">Trainer</h1>
          <h2 className="subha" onClick={ShowTrainer}>View All</h2>
        </div>
        <Table data={clubTrainersEarnings} onViewFull={() => { }} showAction={false} />
      </div>
    </div>
  );
};

export default AdminDa;
