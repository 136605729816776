import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";

/**
 * Fetch all team leaders along with their payment subcollection if available.
 * @returns {Promise<Array>} - List of team leaders with payment details.
 */
export const getTeamLeaders = async () => {
  try {
    // Reference to the 'users' collection
    const usersRef = collection(db, "users");

    // Query to find users with userType = 'team_leader'
    const q = query(usersRef, where("userTypes", "==", "team_leader"));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Process the results and fetch payment subcollection
    const teamLeaders = await Promise.all(
      querySnapshot.docs.map(async (leaderDoc) => {
        const leaderData = { id: leaderDoc.id, ...leaderDoc.data() };

        // Reference to the 'payment' subcollection
        const paymentRef = collection(db, `users/${leaderDoc.id}/payment`);
        const paymentSnapshot = await getDocs(paymentRef);

        // Check if the subcollection exists
        const payments = paymentSnapshot.empty
          ? [] // No payments found
          : paymentSnapshot.docs.map((paymentDoc) => ({
              paymentId: paymentDoc.id,
              ...paymentDoc.data(),
            }));

        return { ...leaderData, payments }; // Attach payment details to the team leader
      })
    );

    console.log(teamLeaders);
    return teamLeaders;
  } catch (error) {
    console.error("Error getting team leaders with payments:", error);
    return [];
  }
};
