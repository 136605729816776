export const sampleNotificationData = [
    { id: 1, message: "You have a new message", type: "info", time: "2 mins ago" },
    { id: 2, message: "Your session will expire soon", type: "warning", time: "5 mins ago" },
    { id: 3, message: "File uploaded successfully", type: "success", time: "10 mins ago" },
    { id: 4, message: "Failed to fetch data", type: "error", time: "15 mins ago" },
    { id: 5, message: "New friend request received", type: "info", time: "20 mins ago" },
    { id: 6, message: "System update available", type: "info", time: "30 mins ago" },
    { id: 7, message: "Your payment was successful", type: "success", time: "1 hour ago" },
    { id: 8, message: "Password changed successfully", type: "success", time: "2 hours ago" },
    { id: 9, message: "Unusual login attempt detected", type: "warning", time: "3 hours ago" },
    { id: 10, message: "Server maintenance scheduled", type: "info", time: "5 hours ago" },
    { id: 11, message: "You have a new message", type: "info", time: "2 mins ago" },
    { id: 12, message: "Your session will expire soon", type: "warning", time: "5 mins ago" },
    { id: 13, message: "File uploaded successfully", type: "success", time: "10 mins ago" },
    { id: 14, message: "Failed to fetch data", type: "error", time: "15 mins ago" },
    { id: 15, message: "New friend request received", type: "info", time: "20 mins ago" },
    { id: 16, message: "System update available", type: "info", time: "30 mins ago" },
    { id: 17, message: "Your payment was successful", type: "success", time: "1 hour ago" },
    { id: 18, message: "Password changed successfully", type: "success", time: "2 hours ago" },
    { id: 19, message: "Unusual login attempt detected", type: "warning", time: "3 hours ago" },
    { id: 20, message: "Server maintenance scheduled", type: "info", time: "5 hours ago" }
];
