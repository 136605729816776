import React, { useState, useEffect } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import styles from "../../../styles/Register.module.css"

import { auth, db } from "../../../firebase-config";
import { getCurrentTimestamp } from "../../../service/time/getCurrentTimestamp";
import { notification } from "antd";
import RegisterLogo from "../../../components/CssComponents/RegisterLogo/RegisterLogo";
import ArLogo from "../../../components/CssComponents/ArLogo/ArLogo";
import PhoneInputComponent from "../../../components/CssComponents/PhoneInput/PhoneInputComponent";
import ButtonComponent from "../../../components/CssComponents/ButtonComponent/ButtonComponent";
import { Link } from "react-router-dom";
import OtpInputContainer from "../../../components/FlowComponents/OtpInputContainer/OtpInputContainer";

const TeamMemberRegister = () => {
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [uid, setUid] = useState('');

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    aadharNumber: "",
    panNumber: "",
    address: "",
    accountNumber: "",
    accountType: "",
    ifscCode: "",
    userTypes: "team_member",
    referralId: "",
    myARID: "",
    createdAt: "",
    updatedAt: "",
  });

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        console.log('reCAPTCHA solved');
      }
    });

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);

  const handleOtpChange = (otpComing) => {
    setOtp(otpComing);
  };

  const sendVerificationCode = (e) => {
    e.preventDefault();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, formData.phone, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        notification.success({
          message: 'OTP Sent',
          description: 'OTP sent to your phone.',
          placement: 'topRight',
          duration: 3, // Display for 3 seconds
        });

      })
      .catch((error) => {
        notification.error({
          message: 'OTP Sending Failed',
          description: 'Failed to send OTP. Please try again.',
          placement: 'topRight',
          duration: 3, // Display for 3 seconds
        });

      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (!confirmationResult) {
      notification.info({
        message: 'OTP Request Needed',
        description: 'First request the OTP',
        placement: 'topRight',
        duration: 3,
      }); return;
    }
    confirmationResult.confirm(otp)
      .then(async (result) => {
        const user = result.user;
        setUid(user.uid);
        notification.success({
          message: 'Verification Successful',
          description: `Phone number verified! User: ${user.uid}`,
          placement: 'topRight',
          duration: 3, // Display for 3 seconds
        });


        // Check if the user data already exists
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          // If user data exists, use it and prevent overwriting myARID
          const existingData = userDocSnapshot.data();
          setFormData(existingData);
        } else {
          const createdAt = getCurrentTimestamp();
          // If user data does not exist, generate a new myARID
          const myARID = generateMyARID();
          setFormData(prevData => ({ ...prevData, myARID, createdAt }));
        }
      })
      .catch((error) => {
        console.error('Error verifying OTP:', error);
        notification.error({
          message: 'Verification Failed',
          description: 'Failed to verify OTP. Please try again.',
          placement: 'topRight',
          duration: 3, // Display for 3 seconds
        });

      });
  };

  const generateMyARID = () => {
    const randomNumbers = Math.floor(100000 + Math.random() * 900000); // Generates a 6-digit number
    return `TM${randomNumbers}`;
  };

  const addUserToFirestore = async () => {
    try {
      await setDoc(doc(db, "users", uid), formData, { merge: true });
      // alert("Registration successful! User data saved to Firestore!");
      notification.success({
        message: 'Registration Successful',
        description: 'User data saved to Firestore!',
        placement: 'topRight',
        duration: 3, // Display for 3 seconds
      });

    } catch (error) {
      console.error("Error adding user to Firestore: ", error);
      // alert("Error adding user to Firestore: ", error.message);
      notification.error({
        message: 'Registration Error',
        description: `Error adding user to Firestore: ${error.message}`,
        placement: 'topRight',
        duration: 3, // Display for 3 seconds
      });

    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updatePhoneNumber = (phoneNumber) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: phoneNumber,
    }));
  };


  const handleRegister = (e) => {
    e.preventDefault();

    const updatedAt = getCurrentTimestamp();
    setFormData({ ...formData, updatedAt })

    if (uid) {
      addUserToFirestore();
    } else {
      alert('Please verify OTP first');
    }
  };

  return (
    <>
      <div className={styles.RegisterBackground}>
        <div className={styles.RegisterContainer}>

          <div className={styles.RegisterImage}>
            <RegisterLogo />
          </div>

          <div className={styles.formSection}>
            <ArLogo />
            <div className={styles.headingText}>Club Member Registration</div>
            <form className={styles.formContainer}>

              <div id="recaptcha-container"></div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Phone Number <sup>*</sup></div>
                <PhoneInputComponent
                  value={formData.phone}
                  onChange={updatePhoneNumber}
                />
              </div>


              <ButtonComponent
                onClick={sendVerificationCode}
                className={""}
                buttonText={"Send OTP"}
              />

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Enter Otp <sup>*</sup></div>
                <OtpInputContainer onOtpChange={handleOtpChange} />
              </div>

              <ButtonComponent
                onClick={verifyOtp}
                className={""}
                buttonText={"Verify OTP"}
              />

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>First Name <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  required
                />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Last Name <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Enter last name"
                  required
                />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Email <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="anurag@gklite.in"
                  required
                />
              </div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Referral ID</div>
                <input
                  type="text"
                  className="input"
                  name="referralId"
                  value={formData.referralId}
                  onChange={handleChange}
                  placeholder="Enter Referral ID"
                />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Date of Birth <sup>*</sup></div>
                <input
                  type="date"
                  className={styles.input}
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  required
                />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Aadhar Number <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="aadharNumber"
                  value={formData.aadharNumber}
                  onChange={handleChange}
                  placeholder="Aadhar Number"
                  required />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Pan Number <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="panNumber"
                  value={formData.panNumber}
                  onChange={handleChange}
                  placeholder="Pan Number" required />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Address <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Address" required
                />
              </div>






              <div className={styles.headingText}>Bank Details</div>

              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Account Number <sup>*</sup></div>
                <input
                  type="text"
                  className={styles.input}
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  placeholder="Account Number"
                  required
                />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Account Type <sup>*</sup></div>
                <input type="text" className={styles.input} name="accountType" value={formData.accountType} onChange={handleChange} placeholder="Account Type" required />
              </div>


              <div className={styles.labelInputWrapper}>
                <div className={styles.labelText}>Ifsc Code <sup>*</sup></div>
                <input type="text" className={styles.input} name="ifscCode" value={formData.ifscCode} onChange={handleChange} placeholder="Ifsc Code" required />
              </div>





              <ButtonComponent
                onClick={handleRegister}
                className={""}
                buttonText={"Register"}
              />


              <Link
                to="/teammember?action=login"
                style={{
                  textDecoration: 'none',
                }}
              >
                <div className={styles.alr}>already a member? <span>Login</span></div>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamMemberRegister;
