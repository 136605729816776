// FeedbackTable.js
import React from 'react';
import styles from './FeedbackTable.module.css';
import Star from './star.png';
import ButtonComponent from '../../CssComponents/ButtonComponent/ButtonComponent';

const FeedbackTable = ({ setShowModal }) => {
    return (
        <table className={styles.table} cellSpacing={0}>
            <thead className={styles.tablehead}>
                <tr>
                    <td>Name</td>
                    <td>Average Ratings</td>
                    <td>Your Comments</td>
                    <td>Actions</td>
                </tr>
            </thead>
            <tbody className={styles.tablebody}>
                <tr>
                    <td>Captain America</td>
                    <td className={styles.icons}>
                        <img src={Star} alt="star" />
                        <img src={Star} alt="star" />
                        <img src={Star} alt="star" />
                        <img src={Star} alt="star" />
                        <img src={Star} alt="star" />
                    </td>
                    <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, ratione?</td>
                    <td>
                        <ButtonComponent
                            onClick={() => setShowModal(true)}
                            buttonText={"Review"}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default FeedbackTable;
