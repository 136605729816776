import { findMonthlyEarningsForUser } from "../findMonthlyEarningsForUser";
import { getTeamLeaders } from "../getUsers/getTeamLeaders";

export const getTeamLeadersEarnings = async () => {
  try {
    const teamLeaders = await getTeamLeaders();

    // Fetch monthly earnings and latest payment
    const teamLeadersEarnings = await Promise.all(
      teamLeaders.map(async (teamLeader) => {
        const monthlyEarnings = await findMonthlyEarningsForUser(teamLeader.id);

        // ✅ Extract the latest payment if available
        const latestPayment = teamLeader.payments?.length
          ? teamLeader.payments.reduce((latest, payment) =>
              new Date(payment.year, payment.month - 1, payment.day) > 
              new Date(latest.year, latest.month - 1, latest.day) ? payment : latest
            )
          : null;

        return { ...teamLeader, monthlyEarnings, latestPayment }; // ✅ Attach latest payment
      })
    );

    return teamLeadersEarnings;
  } catch (error) {
    console.error("Error fetching team leaders with monthly earnings:", error);
    return [];
  }
};
