import { getFirestore,doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";


export async function getListOfTeams() {
  const db = getFirestore(); // Initialize the Firestore database
  const usersCollection = collection(db, 'users'); // Reference to the users collection

  try {
    // Query to find users with userTypes set to "team_leader"
    const teamLeadersQuery = query(usersCollection, where('userTypes', '==', 'team_leader'));

    // Execute the query
    const querySnapshot = await getDocs(teamLeadersQuery);

    // Convert the result to an array of users and return it directly
    return querySnapshot.docs.map((doc) => ({
      id: doc.id, // User ID
      ...doc.data(), // User data
    }));
  } catch (error) {
    console.error('Error fetching team leaders:', error);
    throw error;
  }
}

export const getEarningsForMonthAndYear = async (userId, month, year) => {
  const db = getFirestore();

  try {
    // Step 1: Fetch the user document
    const userDocRef = doc(db, `users/${userId}`);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      throw new Error(`User with ID ${userId} does not exist.`);
    }

    const userData = userDocSnap.data();

    // Step 2: Validate if the user is a team leader
    if (userData.userTypes !== "team_leader") {
      throw new Error(`User with ID ${userId} is not a team leader.`);
    }

    // Step 3: Query the earnings subcollection for the specified month and year
    const earningsRef = collection(db, `users/${userId}/earnings`);
    const earningsQuery = query(
      earningsRef,
      where("createdAt", ">=", `${year}-${String(month).padStart(2, "0")}-01 00:00:00`),
      where("createdAt", "<=", `${year}-${String(month).padStart(2, "0")}-31 23:59:59`)
    );

    const earningsSnapshot = await getDocs(earningsQuery);

    if (earningsSnapshot.empty) {
      return 0; // No earnings found for the given month and year
    }

    // Step 4: Calculate total earnings
    let totalEarnings = 0;
    earningsSnapshot.forEach((doc) => {
      const data = doc.data();
      totalEarnings += data.yourAmount || 0; // Accumulate the `yourAmount` field
    });

    return totalEarnings;
  } catch (error) {
    console.error("Error fetching earnings:", error);
    throw error;
  }
};
