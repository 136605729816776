import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db , storage } from "../firebase-config";
import { doc, setDoc } from "firebase/firestore";
import { getCurrentTimestamp } from "./time/getCurrentTimestamp";

export const updateUserData = async (uid, data, file) => {
    if (!uid) throw new Error("User ID is required.");
    if (!data) throw new Error("User data is required.");

    let photoUrl = data.photoUrl;
    // Use the existing URL if no file is uploaded.

    try {
        // If a new file is provided, upload it to Firebase Storage.
        if (file) {
            const storageRef = ref(storage, `userProfileImg/${uid}/profilePhoto`);
            const uploadResult = await uploadBytes(storageRef, file);

            if (!uploadResult) {
                throw new Error("Image upload failed.");
            }

            photoUrl = await getDownloadURL(storageRef);
            if (!photoUrl) {
                throw new Error("Failed to retrieve image download URL.");
            }
        }

        const updatedUserData = {
            ...data,
            photoUrl,
            updatedAt : getCurrentTimestamp()
        }

        console.log("Updating user data:", updatedUserData);

        // Update the user data in the Firebase Realtime Database.
        await setDoc(doc(db, "users", uid), updatedUserData, { merge: true });

        console.log("User data updated successfully.");
        return { ...data, photoUrl };
    } catch (error) {
        console.error("Error updating user data:", error);
        throw error; // Rethrow the error to handle it in the caller function.
    }
};
