import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../../firebase-config";

/**
 * Fetch all club trainers along with their payment subcollection if available.
 * @returns {Promise<Array>} - List of club trainers with payment details.
 */
export const getClubTrainers = async () => {
  try {
    // Reference to the 'users' collection
    const usersRef = collection(db, "users");

    // Query to find users with userType = 'club_trainer'
    const q = query(usersRef, where("userTypes", "==", "club_trainer"));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Process the results and fetch payment subcollection
    const clubTrainers = await Promise.all(
      querySnapshot.docs.map(async (trainerDoc) => {
        const trainerData = { id: trainerDoc.id, ...trainerDoc.data() };

        // Reference to the 'payment' subcollection
        const paymentRef = collection(db, `users/${trainerDoc.id}/payment`);
        const paymentSnapshot = await getDocs(paymentRef);

        // Check if the subcollection exists
        const payments = paymentSnapshot.empty
          ? [] // No payments found
          : paymentSnapshot.docs.map((paymentDoc) => ({
              paymentId: paymentDoc.id,
              ...paymentDoc.data(),
            }));

        return { ...trainerData, payments }; // Attach payment details to the trainer
      })
    );

    console.log(clubTrainers);
    return clubTrainers;
  } catch (error) {
    console.error("Error getting club trainers with payments:", error);
    return [];
  }
};
