// TermsAndConditions.js
import React, { useEffect, useState } from "react";
import styles from "./TermsAndConditions.module.css";
import Header from "../landing/header/Header";
import Footer from "../landing/Footer/Footer";
import HeaderLoginOptions from "../components/LandingComponents/HeaderLoginOptions/HeaderLoginOptions";
import HeaderRegisterOptions from "../components/LandingComponents/HeaderRegisterOptions/HeaderRegisterOptions";

const TermsAndConditions = () => {
  useEffect(() => {
    const originalStyles = {
      fontFamily: document.body.style.fontFamily,
      margin: document.body.style.margin,
      padding: document.body.style.padding,
      boxSizing: document.body.style.boxSizing,
      outline: document.body.style.outline,
      border: document.body.style.border,
      textDecoration: document.body.style.textDecoration,
      fontSize: document.documentElement.style.fontSize,
      overflowX: document.documentElement.style.overflowX,
      scrollBehavior: document.documentElement.style.scrollBehavior,
      scrollPaddingTop: document.documentElement.style.scrollPaddingTop,
    };

    // Apply global styles
    document.body.style.fontFamily = '"DM Sans", sans-serif';
    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.boxSizing = "border-box";
    document.body.style.outline = "none";
    document.body.style.border = "none";
    document.body.style.textDecoration = "none";
    document.documentElement.style.fontSize = "62.5%";
    document.documentElement.style.overflowX = "hidden";
    document.documentElement.style.scrollBehavior = "smooth";
    document.documentElement.style.scrollPaddingTop = "7rem";

    return () => {
      // Cleanup global styles
      document.body.style.fontFamily = originalStyles.fontFamily;
      document.body.style.margin = originalStyles.margin;
      document.body.style.padding = originalStyles.padding;
      document.body.style.boxSizing = originalStyles.boxSizing;
      document.body.style.outline = originalStyles.outline;
      document.body.style.border = originalStyles.border;
      document.body.style.textDecoration = originalStyles.textDecoration;
      document.documentElement.style.fontSize = originalStyles.fontSize;
      document.documentElement.style.overflowX = originalStyles.overflowX;
      document.documentElement.style.scrollBehavior = originalStyles.scrollBehavior;
      document.documentElement.style.scrollPaddingTop = originalStyles.scrollPaddingTop;
    };
  }, []);

  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [activeHash, setActiveHash] = useState(window.location.hash || "#home");
    const [isHovered, setIsHovered] = useState(false);

    const openRegister = () => {
        setIsRegisterOpen(true);
    };

    const closeRegister = () => {
        setIsRegisterOpen(false);
    };

    const openLogin = () => {
        setIsHovered(false)
        setIsLoginOpen(true);
    };

    const closeLogin = () => {
        setIsLoginOpen(false);
    };

    // Update `activeHash` when URL fragment changes
    useEffect(() => {
        const handleHashChange = () => {
            setActiveHash(window.location.hash || "#home");
        };

        // Listen for hash changes
        window.addEventListener("hashchange", handleHashChange);

        // Cleanup event listener
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

  return (
    <>
    <HeaderLoginOptions isLoginOpen={isLoginOpen} closeLogin={closeLogin} />
    <HeaderRegisterOptions isRegisterOpen={isRegisterOpen} closeRegister={closeRegister} />
      <Header 
      activeHash={activeHash}
      openLogin={openLogin}
      openRegister={openRegister}
      isHovered={isHovered}
      setIsHovered={setIsHovered}
      />
      <div className={styles.termsContainer}>
        <h1 className={styles.heading}>Terms and Conditions</h1>
        <div className={styles.content}>
          <p>
            Welcome to [Your Company Name]! These terms and conditions outline
            the rules and regulations for the use of our website, located at
            [Your Website URL].
          </p>
          <p>
            By accessing this website, we assume you accept these terms and
            conditions. Do not continue to use [Your Website Name] if you do
            not agree to take all of the terms and conditions stated on this
            page.
          </p>
          <h2>License</h2>
          <p>
            Unless otherwise stated, [Your Company Name] and/or its licensors
            own the intellectual property rights for all material on [Your
            Website Name]. All intellectual property rights are reserved. You
            may access this from [Your Website Name] for your own personal use
            subjected to restrictions set in these terms and conditions.
          </p>
          <p>You must not:</p>
          <ul>
            <li>Republish material from [Your Website Name]</li>
            <li>Sell, rent, or sub-license material from [Your Website Name]</li>
            <li>Reproduce, duplicate, or copy material from [Your Website Name]</li>
            <li>Redistribute content from [Your Website Name]</li>
          </ul>
          <h2>Hyperlinking to our Content</h2>
          <p>
            Organizations may link to our website without prior written
            approval under the following conditions:
          </p>
          <ul>
            <li>
              The link does not mislead or falsely imply sponsorship,
              endorsement, or approval.
            </li>
            <li>The link fits within the context of the linking site.</li>
          </ul>
          <h2>Disclaimer</h2>
          <p>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties, and conditions relating to our website
            and its use. Nothing in this disclaimer will:
          </p>
          <ul>
            <li>Limit or exclude our or your liability for death or personal injury.</li>
            <li>
              Limit or exclude our or your liability for fraud or fraudulent
              misrepresentation.
            </li>
            <li>
              Exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
