import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";


// Function to fetch monthly revenue
export const getMonthlyRevenue = async () => {
    try {
        const transactionsRef = collection(db, "transactions"); // Adjust collection name if needed
        const querySnapshot = await getDocs(transactionsRef);

        const revenueData = {};

        querySnapshot.forEach((doc) => {
            const transaction = doc.data();

            if (transaction.year && transaction.month && transaction.amount) {
                const key = `${transaction.year}-${transaction.month}`;

                if (!revenueData[key]) {
                    revenueData[key] = 0;
                }
                revenueData[key] += transaction.amount;
            }
        });

        // Sort and format revenue data
        const sortedKeys = Object.keys(revenueData).sort();
        const labels = sortedKeys.map((key) => {
            const [, month] = key.split("-");
            return new Date(2023, month - 1, 1).toLocaleString("en-US", { month: "short" }); // Convert to "Jan", "Feb", etc.
        });

        const data = sortedKeys.map((key) => revenueData[key]);

        return { labels, data };
    } catch (error) {
        console.error("Error fetching monthly revenue:", error);
        return { labels: [], data: [] };
    }
};
