import React from "react";
import { motion } from "framer-motion";
import styles from "../Header.module.css";
import ButtonComponent from "../../../components/CssComponents/ButtonComponent/ButtonComponent"

const HeaderButtons = ({ toggleNavbar, openLogin, openRegister, isHovered, setIsHovered }) => {
    return (
        <div className={styles.headerBtns}>
            <button
                className={styles.btnLogin}
                onClick={() => {
                    openLogin();
                    toggleNavbar();
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                Login
            </button>


            <ButtonComponent
                onClick={() => {
                    openRegister();
                    toggleNavbar();
                }}
                buttonText={"Sign Up for Free"}
            />

        </div >
    );
};

export default HeaderButtons;
