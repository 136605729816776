import React, { useEffect, useState } from "react";
import styles from "./ReferallTimeline.module.css"; // Import the CSS Module
import { getMyARIDFromUid } from "../../../../../service/getMyARIDFromUid";
import { findUsersUsingMyARID } from "../../../../../service/findUsers/findUsersUsingMyARID";
import { useAuth } from "../../../../../provider/Auth.provider"
import { notification } from "antd";
import Tree from 'react-d3-tree';
import "./link.css"
import { findUserByUID } from "../../../../../service/findUsers/findUserByUID";
const Timeline = () => {
  const { user, loading } = useAuth();
  const [referredUsers, setReferredUsers] = useState(null);
  const [userDetail,setUserDetail]=useState(null)
  const convertToTreeData = (data) => {
    if (!data || !Array.isArray(data)) {
      return []; // Return an empty array if data is invalid
    }
  
    // Map API data into tree children
    const children = data.map((item) => ({
      name: `${item.firstName || "Unknown"} ${item.lastName || ""}`.trim(),
    }));
  
    // Construct the tree
    return [
      {
        name: `${userDetail?.firstName|| 'You'} ${userDetail?.lastName||""}`,
        children,
      },
    ];
  };
  
  const getDynamicPathClass = ({ source, target }, orientation) => {
    if (!target.children) {
      // Leaf node link
      return "link__to-leaf";
    }
    // Branch node link
    return "link__to-branch";
  };
  async function findUsersReferredByUid(user_uid) {
    const myARID = await getMyARIDFromUid(user_uid);
    if (myARID) {
      const usersUsingMyARID = await findUsersUsingMyARID(myARID);
      if (usersUsingMyARID.length > 0) {
        const convertedData=convertToTreeData(usersUsingMyARID)
        setReferredUsers(convertedData);
        console.log("User", user);        
      } else {
        notification.info({
          message: 'No students or team members found',
          description: `No users found with this referralId.`,
          placement: 'topRight',
          duration: 3,
        });
      }
    } else {
      notification.info({
        message: 'AR ID is empty',
        description: `AR ID not found`,
        placement: 'topRight',
        duration: 3,
      });
    }
  }

  useEffect( () => {
    const fetch=async ()=>{
      if (user.uid) {
        const userData= await findUserByUID(user.uid)
        console.log("UserData",userData);
        
        setUserDetail(userData)
        findUsersReferredByUid(user.uid);
      }
      else {
        console.log("user uid not found")
      }
    }
    fetch()
  }, [user]);
 
  const renderCustomNode = ({ nodeDatum }) => {
    const nodeWidth = 100; // Width of the node container
    const nodeHeight = 100; // Height of the node container
  
    return (
      <foreignObject
        width={nodeWidth}
        height={nodeHeight}
        x={-nodeWidth / 2} // Center horizontally
        y={-nodeHeight / 2} // Center vertically
      >
        <div className="custom-node">
          {nodeDatum.name}
        </div>
      </foreignObject>
    );
  };
  
  

  // Set tree orientation to horizontal
  const orientation = 'vertical';
  return (
    // <div className={styles.bo}>
    //   <h1 className={styles.leader}>Team leader</h1>
    //   <div className={styles["timeline-container"]}>
    //     <section className={styles["timeline-section"]}>
    //       <div className={styles.scrollable}>
    //         <div className={styles.line}></div>
    //         <div className={styles.lineparent}>
    //           {/* Dynamically render vertical lines */}
    //           {referredUsers &&
    //             referredUsers.map((_, index) => (
    //               <div key={index} className={styles.vertical}></div>
    //             ))}
    //         </div>
    //         <div className={styles.children}>
    //           {referredUsers ? (
    //             referredUsers.map((user, index) => (
    //               <div key={index} className={styles.child}>
    //                 <div className={styles.txt}>
    //                   {user.firstName || "No Name Available"}
    //                 </div>
    //               </div>
    //             ))
    //           ) : (
    //             <div className={styles.child}>Loadingsss...</div>
    //           )}
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // </div>
    <div className={styles.bo}>
    {referredUsers ? (
      <Tree
        data={referredUsers}
        orientation={orientation}
        translate={{ x: 300, y: 50 }}
        pathFunc="elbow"
        collapsible={true}
        pathClassFunc={getDynamicPathClass}
        renderCustomNodeElement={renderCustomNode}

      />
    ) : (
      <p>Loading tree data...</p> // Loading state
    )}
  </div>
   
  );
};

export default Timeline;
