import { findMonthlyEarningsForUser } from "../findMonthlyEarningsForUser";
import { getTeamMembers } from "../getUsers/getTeamMembers";

export const getTeamMembersEarnings = async () => {
  try {
    const teamMembers = await getTeamMembers();

    // Fetch monthly earnings and latest payment
    const teamMembersEarnings = await Promise.all(
      teamMembers.map(async (teamMember) => {
        const monthlyEarnings = await findMonthlyEarningsForUser(teamMember.id);

        // ✅ Extract the latest payment if available
        const latestPayment = teamMember.payments?.length
          ? teamMember.payments.reduce((latest, payment) =>
              new Date(payment.year, payment.month - 1, payment.day) > 
              new Date(latest.year, latest.month - 1, latest.day) ? payment : latest
            )
          : null;

        return { ...teamMember, monthlyEarnings, latestPayment }; // ✅ Attach latest payment
      })
    );

    return teamMembersEarnings;
  } catch (error) {
    console.error("Error fetching team members with monthly earnings:", error);
    return [];
  }
};
