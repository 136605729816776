import React from "react";
import styles from "./Attendance.module.css";
import close from "./close.png";
import AttendanceTable from "../AttendanceTable/AttendanceTable";
import ButtonComponent from "../../../../../components/CssComponents/ButtonComponent/ButtonComponent";

const Attendance = ({
  showAttendanceModal,
  closeAttendanceModal,
  singleCourseName,
  singleCourseModule,
  studentsEnrolledInCourse,
  handleTrainerEarnings
}) => {
  return (
    <div className={showAttendanceModal ? styles.modal : styles.displayNone}>
      <div className={styles.modalMain}>
        <div className={styles.closebtn} onClick={closeAttendanceModal}>
          <img src={close} alt="Close" />
        </div>
        <div className={styles.mainc}>
          <h1 className={styles.heading}> Attendance Information</h1>
          <div className={styles.wrapper}>
            <div className={styles.wrapperItem}>
              <div>My Attendance</div>
              <ButtonComponent
                onClick={handleTrainerEarnings}
                buttonText={"Mark Present"}
              />
            </div>
            <div className={styles.wrapperItem}>
              <div>{singleCourseName.courseName} / {singleCourseModule.name}</div>
              <ButtonComponent
                onClick={closeAttendanceModal}
                buttonText={"Mark Completed"}
              />
            </div>
          </div>
        </div>
        <AttendanceTable
          singleCourseName={singleCourseName}
          singleCourseModule={singleCourseModule}
          studentsEnrolledInCourse={studentsEnrolledInCourse}
        />
      </div>
    </div>
  );
};

export default Attendance;
